import React, { useEffect, useState } from "react";
import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { hasFeature, getNavMenu } from "./Dashboard.utils";
import NavMenu from "./NavMenu";
import ProfileDropdown from "./ProfileDropdown";
import cx from "classnames";
import Power from "icons/Power";
import { useAppState } from "config/store";
import { TIMEOUT_ERROR } from "constants/response";
import Loading from "components/Shared/Loading";
import useBusiness from "hooks/useBusiness";
import Plus from "icons/Plus";
import CheckCircle from "icons/CheckCircle";
import Hidden from "components/Shared/Hidden";
import { FeatureFlag } from "types/enum";
import Initials from "components/Shared/Initials/Initials";
import { NavProduct } from "icons/NavProduct";
import { NavSale } from "icons/NavSale";
import { NavStore } from "icons/NavStore";
import { NavExpense } from "icons/NavExpense";

const Dashboard = (): React.ReactElement => {
  const appState = useAppState();
  const navigate = useNavigate();
  const businesses = appState.member.get()?.businesses || [];
  const activeBusinessId = appState.activeBusinessId.get();

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { handleGetBusiness } = useBusiness();

  const location = useLocation();
  useEffect(() => {
    appState.request.set({ hasTimedOut: false });
  }, []);

  useEffect(() => {
    setLoading(true);
    handleGetBusiness(appState.activeBusinessId.get() as string).catch(() => {
      navigate("/signin");
    });
  }, [appState.activeBusinessId.get()]);

  useEffect(() => {
    setLoading(false);
  }, [appState.business.get()?.id]);

  if (appState.request.get()?.hasTimedOut === true) {
    return (
      <div className="text-center h-screen flex flex-col items-center justify-center">
        <p>{TIMEOUT_ERROR}</p>
        <button
          onClick={() => appState.request.hasTimedOut.set(false)}
          className={cx(
            "rounded-full px-5 py-2 text-center bg-gradient-to-r from-primary to-secondary text-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-primary"
          )}>
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="flex h-screen px-0 sm:px-12 xl:px-40 py-6 xl:pt-10 md:space-x-6 lg:space-x-10 flex-col lg:flex-row">
      {/* Side Bar */}
      <aside
        className="w-full lg:flex-[14rem] lg:flex-grow-0 lg:flex-shrink-0 lg:w-56 lg:h-full px-4 sm:px-0"
        aria-label="Sidebar">
        <div className="lg:fixed flex flex-col lg:h-screen w-full lg:w-56 pb-6 lg:pb-24">
          <div className="w-full flex">
            <button
              data-testid="menuIcon"
              className="p-1.5 mr-2 bg-white rounded-md inline-block lg:hidden"
              onClick={(): void => setShowMenu(!showMenu)}>
              <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 48 48">
                <path d="M6 36v-3h36v3Zm0-10.5v-3h36v3ZM6 15v-3h36v3Z" fill="currentColor" />
              </svg>
            </button>
            <Link to="?" className="lg:pt-4 flex flex-grow" data-testid="overviewLink">
              <img src="/logo.svg" alt="Bloom Logo" className="w-32 sm:w-44" />
            </Link>
          </div>

          <div
            className={cx(
              "hidden lg:flex flex-col flex-grow rounded-lg bg-white md:bg-transparent",
              showMenu && "!flex"
            )}
            data-testid="menuList"
            onClick={(): void => setShowMenu(false)}>
            <Hidden flag={FeatureFlag.DASHBOARD}>
              {/* Overview */}
              <NavMenu
                className="mt-6 lg:mt-12 rounded-lg"
                text="Overview"
                link="/overview"
                icon={
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path
                      d="M0.25 16.2289V5.72888H3.75V16.2289H0.25ZM6.25 16.2289V0.728882H9.75V16.2289H6.25ZM12.25 16.2289V9.72888H15.75V16.2289H12.25Z"
                      fill="currentColor"
                    />
                  </svg>
                }
              />
              {/* Overview */}
            </Hidden>

            {/* Side Menu */}
            <nav className="flex flex-col md:mt-4 py-6 pr-6 rounded-lg bg-white md:mb-40">
              {getNavMenu(appState.business.get()?.unseenNotifications)
                .filter((item) => hasFeature(item.flag, appState.business.get()?.features))
                .map((item) => (
                  <NavMenu {...item} key={item.link} />
                ))}
              {businesses.map((business) => (
                <NavMenu
                  key={business.id}
                  className="md:hidden"
                  text={
                    <span className="flex">
                      {business.name} &nbsp;
                      {business.id == activeBusinessId && (
                        <CheckCircle className="text-primary" width={20} height={20} />
                      )}
                    </span>
                  }
                  icon={<Initials text={business.name} imgUrl={business.logoUrl} />}
                  onClick={(): void => appState.activeBusinessId.set(business.id)}
                  link="?"
                />
              ))}
              <NavMenu
                className="md:hidden"
                text="Add business"
                link="/onboarding/business"
                icon={<Plus width="20" height="20" />}
              />
              <NavMenu className="md:hidden" text="Sign out" link="/signin" icon={<Power />} />
            </nav>
            {/* Side Menu */}

            <div className="flex-col flex-grow hidden md:flex">
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </aside>
      {/* Side Bar */}
      <div className="h-full flex-1 lg:w-[calc(100%-16.5rem)]">
        <Loading loading={loading}>
          <Outlet />
        </Loading>
      </div>
      {/* Bottom Bar */}
      <div className="flex fixed md:hidden bottom-0 left-0 right-0 bg-white shadow-lg shadow-grey-60 pb-1 z-10 justify-between">
        <Link
          to="/overview"
          className={cx(
            "w-1/5 text-center flex justify-center flex-col items-center py-4 space-y-2 border-t-4 border-white",
            matchPath({ path: "/overview", end: false }, location.pathname) &&
              "!text-primary !border-primary"
          )}>
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path
              d="M0.25 16.2289V5.72888H3.75V16.2289H0.25ZM6.25 16.2289V0.728882H9.75V16.2289H6.25ZM12.25 16.2289V9.72888H15.75V16.2289H12.25Z"
              fill="currentColor"
            />
          </svg>
          <span>Overview</span>
        </Link>
        <Link
          to="/products"
          className={cx(
            "w-1/5 text-center flex justify-center flex-col items-center py-4 space-y-2 border-t-4 border-white",
            matchPath({ path: "/products", end: false }, location.pathname) &&
              "!text-primary !border-primary"
          )}>
          <NavProduct />
          <span>Products</span>
        </Link>
        <Link
          to="/sales/overview"
          className={cx(
            "w-1/5 text-center flex justify-center flex-col items-center py-4 space-y-2 border-t-4 border-white",
            matchPath({ path: "/sales", end: false }, location.pathname) &&
              "!text-primary !border-primary"
          )}>
          <NavSale />
          <span>Sales</span>
        </Link>
        {hasFeature(FeatureFlag.STORE, appState.business.get()?.features) && (
          <Link
            to="/store"
            className={cx(
              "w-1/5 text-center flex justify-center flex-col items-center py-4 space-y-2 border-t-4 border-white",
              matchPath({ path: "/store", end: false }, location.pathname) &&
                "!text-primary !border-primary"
            )}>
            <NavStore />
            <span>Store</span>
          </Link>
        )}
        <Link
          to={"/expenses"}
          className={cx(
            "w-1/5 text-center flex justify-center flex-col items-center py-4 space-y-2 border-t-4 border-white",
            matchPath({ path: "/expenses", end: false }, location.pathname) &&
              "!text-primary !border-primary"
          )}>
          <NavExpense />
          <span>Expenses</span>
        </Link>
      </div>
    </div>
  );
};

export default Dashboard;
