import IconButton from "components/Shared/IconButton";
import Plus from "icons/Plus";
import React from "react";
import { Link } from "react-router-dom";
import { ExpensesProps } from "types";
import Amount from "components/Shared/Amount";
import Dropdown from "components/Shared/Dropdown";
import Kebeb from "icons/Kebeb";
import Edit from "icons/Edit";
import Delete from "icons/Delete";
import ConfirmationDialog from "components/Shared/ConfirmationDialog";
import Wallet from "icons/Wallet";
import Table from "components/Shared/Table";
import DateFilter from "components/Shared/DateFilter";
import Disclosure from "components/Shared/Disclosure";
import { formatMonthRange } from "helpers/date";
import Sort from "components/Shared/Sort";

const Expenses = (props: ExpensesProps): React.ReactElement => {
  const { expenses, expensesSummary, sort, dateRange, handleDateRangeChange, deleteExpense } =
    props;

  return (
    <div className="">
      <div className="bg-white sm:rounded-lg p-4 lg:p-6 mt-2 md:mt-7">
        <div className="flex flex-col-reverse sm:flex-row justify-between items-center pb-6">
          <DateFilter
            dateRange={dateRange}
            handleDateRangeChange={(date): void => handleDateRangeChange(date)}
          />
          <Link to="/expenses/create" data-testid="createExpense" className="mb-4 sm:mb-0">
            <IconButton icon={<Plus />}>Add new expense</IconButton>
          </Link>
        </div>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 text-white">
          <div className="w-full rounded bg-gradient-to-r from-primary to-secondary p-3 md:p-6 flex justify-between">
            <div>
              <p className="text-sm md:text-base	font-semibold">Total expenses</p>
              <h2 className="text-2xl md:text-4xl font-bold mt-2">
                <Amount amount={expensesSummary?.totalAmount} />
              </h2>
            </div>
            <Wallet />
          </div>
        </div>
        <div className="hidden sm:block">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.HeadData {...sort} field="name">
                  Item
                </Table.HeadData>
                <Table.HeadData {...sort} field="category">
                  Category
                </Table.HeadData>
                <Table.HeadData {...sort} field="amountForMonth">
                  Amount
                </Table.HeadData>
                <Table.HeadData>Recurring</Table.HeadData>
                <Table.HeadData />
              </Table.Row>
            </Table.Head>
            <Table.Body className="text-dark-grey" data-testid="expenses">
              {expenses.map(
                (expense): React.ReactElement => (
                  <Table.Row
                    key={expense.id}
                    className="rounded-lg"
                    data-testid="expense"
                    to={`${expense.id}/edit`}>
                    <Table.Data data-testid="expenseName">{expense.name}</Table.Data>
                    <Table.Data data-testid="expenseCategory">{expense.category}</Table.Data>
                    <Table.Data className="font-bold" data-testid="expenseAmount">
                      {expense.periodMonths === 1 ? (
                        <Amount amount={expense.amountForMonth} />
                      ) : (
                        <>
                          <Amount amount={expense.amountForMonth} /> (of&nbsp;
                          <Amount amount={expense.amountTotal} />)
                        </>
                      )}
                    </Table.Data>
                    <Table.Data data-testid="expenseIsRecurring">
                      {expense.isRecurring ? "Yes" : "No"}
                    </Table.Data>
                    <Table.Data className="text-dark-grey">
                      <Dropdown
                        menu={[
                          {
                            text: "Edit",
                            icon: <Edit />,
                            link: `${expense.id}/edit`
                          },
                          {
                            text: "Delete",
                            icon: <Delete />,
                            onClick: () => deleteExpense.handleConfirmDelete(expense)
                          }
                        ]}>
                        <Kebeb />
                      </Dropdown>
                    </Table.Data>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        </div>
        <div className="block sm:hidden mt-4">
          <Sort
            {...sort}
            fields={[
              { field: "name", name: "Item" },
              { field: "category", name: "Category" },
              { field: "amountForMonth", name: "Amount" }
            ]}
          />
          {expenses.map(
            (expense): React.ReactElement => (
              <Disclosure key={expense.id} data-testid="expenseMobile" expanded closable={false}>
                <div className="flex justify-between">
                  <Link to={`${expense.id}/edit`} className="flex-grow">
                    <p className="font-bold text-lg flex-grow" data-testid="expenseName">
                      {expense.name}
                    </p>
                    <p className="mt-2" data-testid="expenseAmount">
                      <span className="font-bold "> Amount: </span>
                      {expense.periodMonths === 1 ? (
                        <Amount amount={expense.amountForMonth} />
                      ) : (
                        <>
                          <Amount amount={expense.amountForMonth} /> &nbsp;
                          <small>
                            (of&nbsp;
                            <Amount amount={expense.amountTotal} />)
                          </small>
                        </>
                      )}
                    </p>
                    <p className="" data-testid="expenseCategory">
                      <span className="font-bold inline-flex">Category:</span> {expense.category}
                    </p>
                    <p className="" data-testid="expenseIsRecurring">
                      <span className="font-bold inline-flex">Recurring:</span>
                      {expense.isRecurring ? "Yes" : "No"}
                    </p>
                  </Link>
                  <Dropdown
                    menu={[
                      {
                        text: "Edit",
                        icon: <Edit />,
                        link: `${expense.id}/edit`
                      },
                      {
                        text: "Delete",
                        icon: <Delete />,
                        onClick: () => deleteExpense.handleConfirmDelete(expense)
                      }
                    ]}>
                    <Kebeb />
                  </Dropdown>
                </div>
              </Disclosure>
            )
          )}
        </div>
        {expenses.length == 0 && (
          <div data-testid="noActivities" className="p-4 md:px-6">
            No expenses created for {formatMonthRange(dateRange)}
          </div>
        )}
      </div>
      <ConfirmationDialog
        message={deleteExpense.deleteConfirmationMessage}
        handleContinue={deleteExpense.handleDeleteConfirmation}
        handleCancel={deleteExpense.handleDeleteCancellation}
      />
    </div>
  );
};

export default Expenses;
