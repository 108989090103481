import { load } from "helpers/analytics";
import React, { useEffect } from "react";
import AppRoutes from "routes";
import "./App.css";
import { HelmetProvider } from "react-helmet-async";

function App() {
  useEffect(() => {
    load();
  }, []);

  return (
    <HelmetProvider>
      <AppRoutes />
    </HelmetProvider>
  );
}

export default App;
