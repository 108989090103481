import React from "react";
import { DefaultComponentProps } from "types";
import cx from "classnames";

const Title = (props: DefaultComponentProps): React.ReactElement => {
  const { children, className, ...titleProps } = props;
  return (
    <h2
      data-testid="title"
      {...titleProps}
      className={cx("text-left text-2xl sm:text-3xl md:text-4xl font-bold", className)}>
      {children}
    </h2>
  );
};

export default Title;
