import React, { SVGProps } from "react";

export const NavOfferings = (props?: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_3969_6838" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="22">
      <rect y="0.478516" width="21" height="21" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_3969_6838)">
      <path
        d="M2.625 3.10352H9.625V10.1035H2.625V3.10352ZM11.375 3.10352H18.375V10.1035H11.375V3.10352ZM2.625 11.8535H9.625V18.8535H2.625V11.8535ZM14 11.8535H15.75V14.4785H18.375V16.2285H15.75V18.8535H14V16.2285H11.375V14.4785H14V11.8535ZM13.125 4.85352V8.35352H16.625V4.85352H13.125ZM4.375 4.85352V8.35352H7.875V4.85352H4.375ZM4.375 13.6035V17.1035H7.875V13.6035H4.375Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
