import React from "react";
import { SortProps } from "types";
import Dropdown from "../Dropdown";
import SortIcon from "icons/Sort";

const Sort = (props: SortProps): React.ReactElement => {
  const { selectedField, handleSort, fields } = props;
  return (
    <div className="flex pl-0 justify-end mb-2">
      <Dropdown
        dropdownClass="w-auto"
        menu={fields.map((field) => ({
          text: <span className="whitespace-nowrap">{field.name}</span>,
          icon: <></>,
          onClick: () => handleSort?.(field.field)
        }))}>
        <span className="link px-2 flex items-center">
          <SortIcon height="20" /> Sort by
          {selectedField && `: ${fields.find((field) => field.field == selectedField)?.name}`}
        </span>
      </Dropdown>
    </div>
  );
};

export default Sort;
