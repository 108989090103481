import { OrderItem } from "components/Orders/Orders";
import Amount from "components/Shared/Amount";
import ConfirmationDialog from "components/Shared/ConfirmationDialog";
import Title from "components/Shared/Title";
import { formatTo } from "helpers/date";
import CreditCard from "icons/CreditCard";
import { NavOfferings } from "icons/NavOfferings";
import { NavOrder } from "icons/NavOrder";
import Plus from "icons/Plus";
import React from "react";
import { Link } from "react-router-dom";
import { OrdersProps } from "types";

export const Sales = (props: OrdersProps): React.ReactElement => {
  const {
    orders,
    ordersSummary,
    dateRange,
    handleConfirmDelete,
    deleteConfirmationMessage,
    handleDeleteConfirmation,
    handleDeleteCancellation
  } = props;

  return (
    <div className="">
      <div className="flex justify-between items-center px-4 md:px-0">
        <Title className="pt-2 p4-6">Sales</Title>
      </div>
      <div className="p-4 lg:p-6 mt-2 sm:mt-4">
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 text-white">
          <div className="w-full rounded bg-gradient-to-r from-primary to-secondary p-3 md:p-6 flex justify-between">
            <div>
              <p className="text-sm md:text-base	font-semibold">
                Total orders for {formatTo(dateRange.startDate, "LLLL")}
              </p>
              <h2 className="text-2xl md:text-4xl font-bold mt-2">
                <Amount amount={ordersSummary?.totalAmount} />
              </h2>
            </div>
            <CreditCard />
          </div>
          <div className="w-full rounded bg-grey-60 p-3 md:p-6 flex justify-between">
            <div>
              <p className="text-sm md:text-base	font-semibold">
                Amount due for {formatTo(dateRange.startDate, "LLLL")}
              </p>
              <h2 className="text-2xl md:text-4xl font-bold mt-2">
                <Amount amount={ordersSummary?.totalAmountDue} />
              </h2>
            </div>
            <CreditCard />
          </div>
        </div>
      </div>
      <div className="mt-2 sm:mt-4 px-4 lg:px-6">
        <div className="bg-white p-4 lg:p-6 rounded">
          <p className="font-bold text-grey-50">Quick links</p>
          <div className="flex gap-4 mt-4">
            <Link to="/sales/orders/create" className="flex flex-col items-center">
              <span className="text-white p-2 rounded-full bg-primary">
                <Plus width="24" height="24" />
              </span>
              <span className="text-primary font-bold text-sm mt-1">Add Order</span>
            </Link>
            <Link to="/sales/orders" className="flex flex-col items-center">
              <span className="text-white p-2 rounded-full bg-primary">
                <NavOrder width="24" height="24" />
              </span>
              <span className="text-primary font-bold text-sm mt-1">View Orders</span>
            </Link>
            <Link to="/sales/offerings" className="flex flex-col items-center">
              <span className="text-white p-2 rounded-full bg-primary">
                <NavOfferings width="24" height="24" />
              </span>
              <span className="text-primary font-bold text-sm mt-1">Offerings</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-4 p-4 lg:p-6 bg-white">
        <p className="font-bold text-grey-50">
          {orders.length > 1 && (
            <>
              {orders.length} pending payments for {formatTo(dateRange.startDate, "LLLL")}
            </>
          )}
          {orders.length == 1 && (
            <>
              {orders.length} pending payment for {formatTo(dateRange.startDate, "LLLL")}
            </>
          )}
          {orders.length == 0 && (
            <>No pending payment for {formatTo(dateRange.startDate, "LLLL")}</>
          )}
        </p>
        {orders.map(
          (order): React.ReactElement => (
            <OrderItem key={order.ref} order={order} handleConfirmDelete={handleConfirmDelete} />
          )
        )}
      </div>
      <ConfirmationDialog
        message={deleteConfirmationMessage}
        handleContinue={handleDeleteConfirmation}
        handleCancel={handleDeleteCancellation}
      />
    </div>
  );
};
