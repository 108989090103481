import React, { SVGProps } from "react";

export const NavOrder = (props?: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.06083 15.4165C3.70635 15.4165 3.38861 15.3102 3.1076 15.0976C2.8266 14.8851 2.63617 14.606 2.53634 14.2605L0.456528 6.79781C0.389225 6.54877 0.432414 6.31881 0.586093 6.10792C0.739787 5.89701 0.948438 5.79156 1.21205 5.79156H5.34305L8.83966 0.55674C8.91258 0.445688 9.00625 0.359311 9.12069 0.297609C9.23511 0.235921 9.35962 0.205078 9.49423 0.205078C9.62885 0.205078 9.75337 0.237329 9.86779 0.301831C9.98221 0.366333 10.0759 0.45411 10.1488 0.565162L13.6286 5.79156H17.7865C18.0501 5.79156 18.2602 5.89701 18.4167 6.10792C18.5732 6.31881 18.615 6.54877 18.542 6.79781L16.4454 14.2605C16.3456 14.606 16.1551 14.8851 15.8741 15.0976C15.5931 15.3102 15.2754 15.4165 14.9209 15.4165H4.06083ZM4.01371 14.104H14.9849C15.0466 14.104 15.0998 14.0858 15.1447 14.0493C15.1896 14.0129 15.2204 13.9638 15.2373 13.9021L17.1118 7.10404H1.88678L3.76129 13.9021C3.77812 13.9638 3.80897 14.0129 3.85385 14.0493C3.89872 14.0858 3.95201 14.104 4.01371 14.104ZM9.49928 11.9165C9.85713 11.9165 10.1653 11.7872 10.4239 11.5287C10.6825 11.2701 10.8118 10.9619 10.8118 10.604C10.8118 10.2462 10.6825 9.93799 10.4239 9.67942C10.1653 9.42085 9.85713 9.29156 9.49928 9.29156C9.14143 9.29156 8.83323 9.42085 8.57467 9.67942C8.31609 9.93799 8.1868 10.2462 8.1868 10.604C8.1868 10.9619 8.31609 11.2701 8.57467 11.5287C8.83323 11.7872 9.14143 11.9165 9.49928 11.9165ZM6.93485 5.79156H12.0503L9.48583 1.93145L6.93485 5.79156Z"
      fill="currentColor"
    />
  </svg>
);
