import React, { useEffect, useState } from "react";
import { page } from "helpers/analytics";
import Alert from "components/Shared/Alert";
import { useAppState } from "config/store";
import { hasFeature } from "../Dashboard/Dashboard.utils";
import { useNavigate } from "react-router-dom";
import { FeatureFlag } from "types/enum";
import { NONE } from "constants/general";

const Page = (props: {
  children: React.ReactElement;
  title: string;
  feature: FeatureFlag | "none";
}): React.ReactElement => {
  const appState = useAppState();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    document.title = props.title;
    page();
  }, [location.pathname]);

  useEffect(() => {
    if (props.feature != NONE && !hasFeature(props.feature, appState.business.get()?.features)) {
      // Default page thats always available
      navigate("/sales/orders");
    } else {
      setLoaded(true);
    }
  }, []);

  if (!loaded) {
    return <></>;
  }

  return (
    <div className="min-h-full pb-32 md:pb-0">
      {props.children} <Alert />
    </div>
  );
};

export default Page;
