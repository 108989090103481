import { TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { RecordPurchaseDialog } from "components/EditStoreItem/RecordPurchaseDialog/RecordPurchaseDialog";
import Amount from "components/Shared/Amount";
import { CreateDialog } from "components/Shared/CreateDialog/CreateDialog";
import Disclosure from "components/Shared/Disclosure";
import Dropdown from "components/Shared/Dropdown";
import IconButton from "components/Shared/IconButton";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";
import { SearchInput } from "components/Shared/SearchInput/SearchInput";
import { TabTitle } from "components/Shared/Tab/Tab";
import Table from "components/Shared/Table";
import Title from "components/Shared/Title";
import { useSearch } from "hooks/shared/useSearch/useSearch";
import { getStoreItemName } from "hooks/useStoreItems/useStoreItems.utils";
import { ContextAdd } from "icons/ContextAdd";
import Edit from "icons/Edit";
import Kebeb from "icons/Kebeb";
import Plus from "icons/Plus";
import { PlusSquare } from "icons/PlusSquare";
import Wallet from "icons/Wallet";
import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { StoreItemProduct, StoreItemRaw, StoreProps } from "types";
import Delete from "icons/Delete";
import ConfirmationDialog from "components/Shared/ConfirmationDialog";

const StoreItem = (props: StoreProps): React.ReactElement => {
  const { storeItems, measurementUnits, dialog, setDialog, storeItemPurchase, deleteStoreItem } =
    props;
  const search = useSearch<StoreItemRaw | StoreItemProduct, "type">();
  const [createIsOpen, setCreateIsOpen] = useState(false);

  const filteredItems = search.advancedFilter(
    (item) => (item.type == "RAW_MATERIAL" ? item.name : `${item.productName} ${item.variantName}`),
    storeItems?.items || []
  );

  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const tabs = ["raw", "product"];

  const [selectedIndex, setSelectedIndex] = useState(tab ? tabs.indexOf(tab) : 0);

  const filteredItemsByTab = filteredItems.filter((item) =>
    selectedIndex == 0 ? item.type == "RAW_MATERIAL" : item.type == "PRODUCT"
  );

  if (!storeItems || storeItems?.items.length === 0) {
    return (
      <div
        className="w-full h-full flex flex-col items-center justify-center"
        data-testid="noItems">
        <img src="images/svg/store.svg" />
        <Title className="pt-10 pb-3">
          Store items
          <InfoTooltip>
            These are the materials you buy or create and use directly in the process of creating
            your product. Store items should have a measurable quantity
          </InfoTooltip>
        </Title>
        <p className="text-grey-50 px-4 text-center">
          All materials involved in the creation of your products and services can be saved and
          tracked here
        </p>
        <IconButton onClick={() => setCreateIsOpen(true)} icon={<Plus />}>
          Add new store item
        </IconButton>
      </div>
    );
  }

  return (
    <div className="">
      <div className="flex sm:flex-row justify-between items-start sm:items-center px-4 md:px-0">
        <Title className="pt-2 pb-6">
          Store items
          <InfoTooltip>
            These are the materials you buy or create and use directly in the process of creating
            your product. Store items should have a measurable quantity
          </InfoTooltip>
        </Title>
        <IconButton onClick={() => setCreateIsOpen(true)} icon={<Plus />}>
          Add new store item
        </IconButton>
      </div>
      <TabGroup selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <TabList className="bg-white flex text-grey-50 font-bold">
          <TabTitle title="Raw Material" isSelected={selectedIndex == 0} icon={<PlusSquare />} />
          <TabTitle title="Product" isSelected={selectedIndex == 1} icon={<ContextAdd />} />
        </TabList>

        <TabPanels>
          <div className="bg-white sm:rounded-lg p-4 lg:p-6 mt-2 md:mt-7">
            <div className="flex flex-col md:flex-row gap-2">
              <div className="flex-1">
                <SearchInput {...search} />
              </div>
              <IconButton
                outlined
                className="mb-2"
                onClick={() => setDialog("purchase")}
                icon={<Wallet width="24" height="24" />}>
                {selectedIndex == 0 ? "Record a purchase" : "Record new batch"}
              </IconButton>
            </div>
            <div className="hidden sm:block">
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.HeadData>Item</Table.HeadData>
                    <Table.HeadData>In stock</Table.HeadData>
                    <Table.HeadData>Amount</Table.HeadData>
                    <Table.HeadData>Cost per unit</Table.HeadData>
                    <Table.HeadData />
                  </Table.Row>
                </Table.Head>
                <Table.Body className="text-dark-grey" data-testid="storeItems">
                  {filteredItemsByTab.map(
                    (item): React.ReactElement => (
                      <Table.Row
                        key={item.id}
                        className="rounded-lg"
                        data-testid="storeItem"
                        to={`${item.id}/edit`}>
                        <Table.Data data-testid="itemName">
                          <b>
                            {item.type == "RAW_MATERIAL" && item.name}
                            {item.type == "PRODUCT" && (
                              <>
                                <span>{item.productName}</span>
                                <br />
                                <small>{item.variantName}</small>
                              </>
                            )}
                          </b>
                        </Table.Data>
                        <Table.Data
                          data-testid="itemQuantity"
                          className={`${
                            item.availableStock < 0 ? "text-red-500" : ""
                          }`}>{`${item.availableStock}${item.unit.symbol}`}</Table.Data>
                        <Table.Data className="font-bold" data-testid="itemAmount">
                          <Amount amount={item.stockValue} />
                        </Table.Data>
                        <Table.Data data-testid="itemUnitCost">
                          <Amount amount={item.costPerUnit} /> {`per ${item.unit.symbol}`}
                        </Table.Data>
                        <Table.Data className="text-dark-grey">
                          <Dropdown
                            menu={[
                              {
                                text: "Edit",
                                icon: <Edit />,
                                link: `${item.id}/edit`
                              },
                              {
                                text: "Delete",
                                icon: <Delete />,
                                onClick: () => deleteStoreItem.handleConfirmDelete(item)
                              }
                            ]}>
                            <Kebeb />
                          </Dropdown>
                        </Table.Data>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
              </Table>
            </div>
            <div className="block sm:hidden mt-4">
              {filteredItemsByTab.map(
                (item): React.ReactElement => (
                  <Disclosure key={item.id} data-testid="itemMobile" expanded closable={false}>
                    <div className="flex justify-between">
                      <Link to={`${item.id}/edit`} className="flex flex-col">
                        <span className="font-bold text-lg" data-testid="itemName">
                          <b>{getStoreItemName(item)}</b>
                        </span>
                        <p
                          className={`mt-2 font-bold ${
                            item.availableStock < 0 ? "text-red-500" : ""
                          }`}
                          data-testid="itemQuantity">
                          In stock: {`${item.availableStock}${item.unit.symbol}`}
                        </p>
                        <p data-testid="itemAmount">
                          <span className="font-bold inline-flex">Amount:</span>
                          <span className="">
                            <Amount amount={item.stockValue} />
                          </span>
                        </p>
                        <p className="" data-testid="itemUnitCost">
                          <span className="font-bold inline-flex">Cost per unit:</span>
                          <span className="">
                            <Amount amount={item.costPerUnit} /> {`per ${item.unit.symbol}`}
                          </span>
                        </p>
                      </Link>
                      <Dropdown
                        menu={[
                          {
                            text: "Edit",
                            icon: <Edit />,
                            link: `${item.id}/edit`
                          },
                          {
                            text: "Delete",
                            icon: <Delete />,
                            onClick: () => deleteStoreItem.handleConfirmDelete(item)
                          }
                        ]}>
                        <Kebeb />
                      </Dropdown>
                    </div>
                  </Disclosure>
                )
              )}
            </div>
            <div>
              {filteredItemsByTab.length == 0 && (
                <p className="text-sm p-4 md:py-0 md:px-6">
                  No {selectedIndex == 0 ? "raw" : "product"} store items match your search criteria
                </p>
              )}
            </div>
          </div>
        </TabPanels>
      </TabGroup>

      <CreateDialog
        isOpen={createIsOpen}
        setIsOpen={setCreateIsOpen}
        title="How do you want to add your store item?"
        option1={{
          link: "create-product",
          title: "From a Product",
          desc: "Add an existing product to the store"
        }}
        option2={{
          link: "create",
          title: "From a raw material",
          desc: "Add your raw material to the store"
        }}
      />

      <RecordPurchaseDialog
        storeItemUnit={
          filteredItemsByTab.find((item) => item.id == storeItemPurchase.purchaseForm.storeItemId)
            ?.unit.name
        }
        storeItemType={selectedIndex == 0 ? "RAW_MATERIAL" : "PRODUCT"}
        error={storeItemPurchase.error}
        measurementUnits={measurementUnits}
        savingPurchase={storeItemPurchase.loaders.savingPurchase}
        storeItemPurchase={storeItemPurchase}
        dialog={dialog}
        setDialog={setDialog}
        storeItems={filteredItemsByTab}
      />
      <ConfirmationDialog
        message={deleteStoreItem.deleteConfirmationMessage}
        handleContinue={deleteStoreItem.handleDeleteConfirmation}
        handleCancel={deleteStoreItem.handleDeleteCancellation}
      />
    </div>
  );
};

export default StoreItem;
