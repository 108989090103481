import { useMutation, useQuery } from "@tanstack/react-query";
import { hasFeature } from "components/Layout/Dashboard/Dashboard.utils";
import api from "config/api";
import { useAppState } from "config/store";
import { ERROR_OCCURRED, FETCH_FAILED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { extractErrorMessage } from "helpers/api";
import { removeAtIndex, replaceAtIndex } from "helpers/array";
import { isNotEmpty, isNumber } from "helpers/validate";
import { useDelete } from "hooks/shared/useDelete/useDelete";
import useSort from "hooks/useSort";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormChangeEvent,
  Product,
  ProductDetail,
  ProductDetailVariant,
  ProductForm,
  ProductLoaders,
  Products,
  ProductVariantForm,
  Response
} from "types";
import { AlertType, FeatureFlag, SegmentEvent } from "types/enum";

const useProduct = () => {
  const navigate = useNavigate();
  const { productId, variantId } = useParams();
  const appState = useAppState();
  const { showAlert } = useAlert();

  // UseStates
  const [productForm, setProductForm] = useState<ProductForm>({
    name: "",
    variants: [{ name: "", defaultSellingPrice: "", targetMargin: "", costPrice: 0 }]
  });
  const [productVariantForm, setProductVariantForm] = useState<ProductVariantForm>({
    name: "",
    defaultSellingPrice: "",
    targetMargin: "",
    costPrice: 0
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [loaders, setLoaders] = useState<ProductLoaders>({
    savingProduct: false
  });
  const [error, setError] = useState("");
  const [variants, setVariants] = useState<ProductDetailVariant[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const sort = useSort(setVariants);

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setProductForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleFormVariantChange = (event: FormChangeEvent, variantIndex: number): void => {
    const { name, value } = event.target;
    setProductForm((prev) => ({
      ...prev,
      variants: replaceAtIndex(
        prev.variants,
        { ...prev.variants[variantIndex], [name]: value },
        variantIndex
      )
    }));
    setError("");
  };

  const handleAddVariant = (): void => {
    setProductForm((prev) => ({
      ...prev,
      variants: [
        ...prev.variants,
        { name: "", defaultSellingPrice: "", targetMargin: "", costPrice: 0 }
      ]
    }));
  };

  const handleRemoveVariant = (variantIndex: number): void => {
    setProductForm((prev) => ({
      ...prev,
      variants: removeAtIndex(prev.variants, variantIndex)
    }));
    setError("");
  };

  const handleCreateProduct = async (): Promise<boolean> => {
    const requestData = {
      name: productForm.name,
      variants: productForm.variants
    };

    try {
      const json: Response<string> = await api.post("product", { json: requestData }).json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.PRODUCT_ADDED, {
          variants: productForm.variants.map(({ defaultSellingPrice }) => ({
            defaultSellingPrice
          })),
          noOfVariants: productForm.variants.length,
          productId: json.data
        });
        showAlert(AlertType.SUCCESS);
        if (hasFeature(FeatureFlag.STORE, appState.business.get()?.features)) {
          navigate(`/products/${json.data}/post-create`);
        } else {
          navigate("/products");
        }
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleUpdateProduct = async (): Promise<boolean> => {
    const requestData = {
      name: productForm.name
    };

    try {
      const json: Response<void> = await api
        .put(`product/${productId}`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        showAlert(AlertType.SUCCESS);
        navigate("/products");
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleCloneProduct = async (): Promise<boolean> => {
    const requestData = {
      name: productForm.name
    };

    try {
      const json: Response<void> = await api
        .post(`product/${productForm.id}/clone`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        showAlert(AlertType.SUCCESS);
        await productsQuery.refetch();
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setLoaders((prev) => ({ ...prev, savingProduct: true }));
    let result;
    if (productId) {
      result = await handleUpdateProduct();
    } else {
      result = await handleCreateProduct();
    }
    setLoaders((prev) => ({ ...prev, savingProduct: false }));
    if (!result) {
      setError(ERROR_OCCURRED);
    }
  };

  const productsQuery = useQuery({
    queryKey: ["products"],
    queryFn: async () => {
      const json: Response<Products> = await api.get("product").json();
      if (json.code === 200) {
        return json.data;
      }
    }
  });

  const productQuery = useQuery({
    queryKey: ["product", productId],
    enabled: !!productId,
    queryFn: async () => {
      setLoaders((prev) => ({ ...prev, fetchingProduct: true }));

      try {
        const json: Response<ProductDetail & { variants: ProductDetailVariant[] }> = await api
          .get(`product/${productId}`)
          .json();
        if (json.code === 200) {
          const { name } = json.data;
          setVariants(
            json.data.variants.map((variant) => ({ ...variant, costTotal: variant.cost.total }))
          );
          setProductForm({ name, variants: [] });
          return json.data;
        }
      } catch (err) {
        showAlert(AlertType.DANGER, FETCH_FAILED);
        console.error(err);
      }
      setLoaders((prev) => ({ ...prev, fetchingProduct: false }));
    }
  });

  const handleProductVariantFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setProductVariantForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleClone = (product: Product): void => {
    setProductForm({
      variants: [],
      name: `Copy of ${product.name}`,
      id: product.id
    });
    setIsOpen(true);
  };

  const handleCreateProductVariant = async (): Promise<boolean> => {
    const requestData = {
      name: productVariantForm.name,
      defaultSellingPrice: +productVariantForm.defaultSellingPrice
    };

    try {
      const json: Response<void> = await api
        .post(`product/${productId}/variant`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.VARIANT_ADDED, {
          defaultSellingPrice: +productVariantForm.defaultSellingPrice,
          productId
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleUpdateProductVariant = async (): Promise<boolean> => {
    const requestData = {
      name: productVariantForm.name,
      defaultSellingPrice: +productVariantForm.defaultSellingPrice
    };

    try {
      const json: Response<void> = await api
        .put(`product/${productId}/variant/${variantId}`, { json: requestData })
        .json();
      return json.code === 200;
    } catch {
      return false;
    }
  };

  const handleProductVariantFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    setLoaders((prev) => ({ ...prev, savingProduct: true }));
    let result;
    if (variantId) {
      result = await handleUpdateProductVariant();
    } else {
      result = await handleCreateProductVariant();
    }
    setLoaders((prev) => ({ ...prev, savingProduct: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
      navigate(`/products/${productId}`);
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  const handleCloneFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setLoaders((prev) => ({ ...prev, savingProduct: true }));
    const result = await handleCloneProduct();
    setLoaders((prev) => ({ ...prev, savingProduct: false }));
    if (result) {
      setIsOpen(false);
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  const handleSort = (field: string): void => {
    sort.sort(variants, field as keyof ProductDetailVariant);
  };

  // UseEffects
  useEffect(() => {
    const isCreatingNewProduct = !productId;

    const variantsAreValid =
      !isCreatingNewProduct ||
      (isCreatingNewProduct &&
        productForm.variants.length > 0 &&
        productForm.variants.reduce((prev, curr) => {
          return prev && isNotEmpty(curr.name) && isNumber(curr.defaultSellingPrice);
        }, true));

    setFormIsValid(isNotEmpty(productForm.name) && variantsAreValid);
  }, [productForm]);

  useEffect(() => {
    if (variantId && productQuery.data) {
      setProductVariantForm((prev) => ({
        ...prev,
        ...variants.find(({ id }) => id === variantId)
      }));
    }
  }, [productQuery.data, variantId]);

  const deleteProductMutation = useMutation({
    mutationFn: async (product: Product) => {
      const json: Response<{ deleted: boolean }> = await api.delete(`product/${product.id}`).json();
      return json?.code === 200 && json.data.deleted;
    },
    onSuccess: (data) => {
      if (data) {
        productsQuery.refetch();
        return true;
      }
    },
    onError: async (error: Error) => {
      showAlert(AlertType.DANGER, await extractErrorMessage(error));
    }
  });

  const deleteVariantMutation = useMutation({
    mutationFn: async (variant: ProductDetailVariant) => {
      const json: Response<void> = await api
        .delete(`product/${productId}/variant/${variant.id}`)
        .json();
      return json?.code === 200;
    },
    onSuccess: (data) => {
      if (data) {
        productQuery.refetch();
        return true;
      }
    },
    onError: async (error: Error) => {
      showAlert(AlertType.DANGER, await extractErrorMessage(error));
    }
  });

  const deleteProduct = useDelete({
    getMessage: (product: Product) =>
      `Are you sure you want to permanently delete ${product.name}?`,
    handleDelete: deleteProductMutation.mutateAsync
  });

  const deleteVariant = useDelete({
    getMessage: (variant: ProductDetailVariant) =>
      `Are you sure you want to permanently delete ${variant.name}?`,
    handleDelete: deleteVariantMutation.mutateAsync
  });

  return {
    productForm,
    formIsValid,
    handleFormChange,
    handleFormSubmit,
    loaders: {
      ...loaders,
      fetchingProduct: productsQuery.isFetching
    },
    error,
    products: productsQuery.data,
    productId,
    handleFormVariantChange,
    handleAddVariant,
    handleRemoveVariant,
    productDetail: productQuery.data,
    variantId,
    productVariantForm,
    handleProductVariantFormChange,
    handleProductVariantFormSubmit,
    isOpen,
    setIsOpen,
    handleClone,
    handleCloneFormSubmit,
    variants,
    sort: { ...sort, handleSort },
    deleteProduct,
    deleteVariant
  };
};

export default useProduct;

export type UseProductType = ReturnType<typeof useProduct>;
