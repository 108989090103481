import React from "react";
import { DefaultComponentProps } from "types";
import cx from "classnames";

const Form = ({ children, ...props }: DefaultComponentProps): React.ReactElement => {
  return <div {...props}>{children}</div>;
};

const Footer = ({
  children,
  spaceBetween,
  center,
  ...props
}: DefaultComponentProps & { spaceBetween?: boolean; center?: boolean }): React.ReactElement => {
  return (
    <div {...props}>
      <hr className="flex-1 h-0.5 m-0 border-soft-grey space-x-4" />
      <div
        className={cx(
          "form-xy flex flex-wrap justify-between",
          spaceBetween ? "justify-between" : "sm:space-x-4",
          center && "justify-center"
        )}>
        {children}
      </div>
    </div>
  );
};

Form.Footer = Footer;
export default Form;
