import React, { useState } from "react";
import { ProductsProps } from "types";
import Dropdown from "components/Shared/Dropdown";
import Kebeb from "icons/Kebeb";
import Edit from "icons/Edit";
import { Link } from "react-router-dom";
import IconButton from "components/Shared/IconButton";
import Plus from "icons/Plus";
import Title from "components/Shared/Title";
import Disclosure from "components/Shared/Disclosure";
import Info from "icons/Info";
import Copy from "icons/Copy";
import Dialog from "components/Shared/Dialog";
import Error from "components/Shared/Error";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";
import { CreateDialog } from "components/Shared/CreateDialog/CreateDialog";
import Delete from "icons/Delete";
import ConfirmationDialog from "components/Shared/ConfirmationDialog";

const Products = (props: ProductsProps): React.ReactElement => {
  const {
    products,
    productForm,
    loaders,
    handleFormChange,
    error,
    handleCloneFormSubmit,
    isOpen,
    setIsOpen,
    handleClone,
    deleteProduct
  } = props;

  const [createIsOpen, setCreateIsOpen] = useState(false);

  return (
    <div className="px-4 md:px-0">
      <div className="flex flex-row justify-between sm:items-center">
        <Title className="py-2" data-testid="title">
          Products <InfoTooltip>These are the products or services you create for sale</InfoTooltip>
        </Title>
        <IconButton onClick={() => setCreateIsOpen(true)} icon={<Plus />}>
          Add new product
        </IconButton>
      </div>
      <div className="mt-7 pb-10" data-testid="products">
        <div className="space-y-3">
          {products?.products.map((product) => (
            <div
              className="flex justify-between bg-white hover:bg-off-white rounded-lg px-6 py-4"
              key={product.id}
              data-testid="product">
              <Link to={`${product.id}`} className="flex-1">
                <p className="font-bold text-lg" data-testid="productsName">
                  {product.name}
                </p>
                <p className="text-grey-60 mt-1" data-testid="productsVariants">
                  {product.variants} Variant{product.variants !== 1 && "s"}
                </p>
              </Link>
              <div className="flex">
                <Link to={`${product.id}`} className="link mr-6" data-testid="viewProduct">
                  View
                </Link>
                <Dropdown
                  menu={[
                    {
                      text: "Edit",
                      icon: <Edit />,
                      link: `${product.id}/edit`
                    },
                    {
                      text: "Clone",
                      icon: <Copy />,
                      onClick: (): void => handleClone(product)
                    },
                    {
                      text: "Delete",
                      icon: <Delete />,
                      onClick: () => deleteProduct.handleConfirmDelete(product)
                    }
                  ]}>
                  <Kebeb />
                </Dropdown>
              </div>
            </div>
          ))}
        </div>
        {!products ||
          (products?.products.length === 0 && (
            <div
              className="flex justify-between bg-white rounded-lg px-6 py-4"
              data-testid="noProducts">
              <div>
                <p className="font-bold text-lg">No products created</p>
              </div>
            </div>
          ))}
      </div>
      <Dialog isOpen={isOpen} setIsOpen={setIsOpen} title="Clone product">
        <form onSubmit={handleCloneFormSubmit} className="" data-testid="cloneForm">
          <Error error={error} />
          <div>
            <TextInput
              type="text"
              name="name"
              id="name"
              value={productForm.name}
              required={true}
              onChange={handleFormChange}
              label="Product name"
              data-testid="nameInput"
            />
          </div>
          <Button
            type="submit"
            data-testid="costSubmitButton"
            disabled={!productForm.name}
            loading={loaders.savingProduct}
            className="w-full mt-6">
            Save
          </Button>
        </form>
      </Dialog>
      <CreateDialog
        isOpen={createIsOpen}
        setIsOpen={setCreateIsOpen}
        title="How do you want to add your product?"
        option1={{
          link: "/templates/library",
          title: "From a Template",
          desc: "Select from pre-configured products in our template gallery"
        }}
        option2={{
          link: "/products/create",
          title: "From Scratch",
          desc: "Create a product and configure it from scratch"
        }}
      />
      <ConfirmationDialog
        message={deleteProduct.deleteConfirmationMessage}
        handleContinue={deleteProduct.handleDeleteConfirmation}
        handleCancel={deleteProduct.handleDeleteCancellation}
      />
    </div>
  );
};

export default Products;
