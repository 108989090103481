import { InvoiceProps } from "types";
import React from "react";
import Button from "components/Shared/Button";
import Title from "components/Shared/Title";
import Initials from "components/Shared/Initials/Initials";
import Amount from "components/Shared/Amount";
import { BANK_DEPOSIT, PAYMENT_LINK } from "constants/general";
import "./Invoice.css";
import { formatReadableDate } from "helpers/date";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Copy from "icons/Copy";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeSanitize, { defaultSchema } from "rehype-sanitize";
import Markdown from "react-markdown";

export const Invoice = (props: InvoiceProps) => {
  const { invoiceDetail, invoiceRef, handleDownload } = props;
  console.log(invoiceDetail?.business);
  return (
    <div className="w-screen bg-soft-grey overflow-y-auto pb-12">
      <div className="bg-white px-4 lg:px-6 py-3 lg:py-5 flex justify-center">
        <div className="flex justify-between items-center w-full md:w-5/6">
          <div className="font-semibold">Invoice #{invoiceDetail?.ref}</div>
          <Button size="md" onClick={handleDownload}>
            Download PDF
          </Button>
        </div>
      </div>
      <div className="flex flex-col items-center pt-16 px-16 origin-top scale-[calc(320/800)] sm:scale-[calc(620/800)] md:scale-[calc(740/800)] lg:scale-[1]">
        <div
          className="bg-white w-[794px] leading-normal font-[Manrope] font-normal font- rounded-lg"
          id="#invoice"
          ref={invoiceRef}>
          <div className="flex justify-between items-center py-10 px-8 border-b-2 border-soft-grey">
            <Title>Invoice</Title>
            <div className="text-right">
              <div>Ref: #{invoiceDetail?.ref}</div>
              {invoiceDetail?.date && (
                <div>Issued on: {formatReadableDate(invoiceDetail?.date)}</div>
              )}
              {invoiceDetail?.dueDate && (
                <div>Due on: {formatReadableDate(invoiceDetail?.dueDate)}</div>
              )}
            </div>
          </div>
          <div className="flex px-8 py-6">
            <div className="w-1/2">
              <h3 className="text-xl font-semibold">From:</h3>
              <div className="flex items-center gap-2 mt-2">
                <Initials
                  text={invoiceDetail?.business.name}
                  imgUrl={invoiceDetail?.business.logoUrl}
                />
                <span className="font-semibold" data-testid="businessName">
                  {invoiceDetail?.business.name}
                </span>
              </div>
            </div>
            <div className="w-1/2">
              <h3 className="text-xl font-semibold">Bill to:</h3>
              <div className="mt-2">
                <b>{invoiceDetail?.customer.name}</b>
              </div>
              <div className="">{invoiceDetail?.customer.email}</div>
            </div>
          </div>
          <div className="px-6 flex mt-6 py-3">
            <p className="text-grey-50 font-semibold w-1/2">Items</p>
            <p className="text-grey-50 font-semibold w-1/6 text-right">Quantity</p>
            <p className="text-grey-50 font-semibold w-1/6 text-right">Unit price</p>
            <p className="text-grey-50 font-semibold w-1/6 text-right">Amount</p>
          </div>
          {invoiceDetail?.invoiceItems.map((item) => (
            <div
              key={item.orderItemId}
              className="flex items-center px-6 border-b-2 border-soft-grey py-3 even:bg-soft-grey no-break">
              <div className="w-1/2">
                {item.name}
                <br />
                <small>{item.description}</small>
              </div>
              <div className="w-1/6 text-right font-semibold">{item.quantity}</div>
              <div className="w-1/6 text-right font-semibold">
                <Amount currency={invoiceDetail?.business.currency} amount={+item.unitPrice} />
              </div>
              <div className="w-1/6 text-right font-semibold">
                <Amount
                  currency={invoiceDetail?.business.currency}
                  amount={+item.unitPrice * item.quantity}
                />
              </div>
            </div>
          ))}
          <div className="no-break">
            {!!invoiceDetail?.discount?.value && !!invoiceDetail?.tax?.taxRate && (
              <div className="flex pt-6 px-6">
                <div className="w-5/6 text-right font-semibold">Sub total:</div>
                <div className="w-1/6 text-right">
                  <Amount
                    currency={invoiceDetail?.business.currency}
                    amount={invoiceDetail?.subTotal}
                  />
                </div>
              </div>
            )}
            {!!invoiceDetail?.discount?.value && (
              <div className="flex text-primary px-6">
                <div className="w-5/6 text-right">Discount:</div>
                <div className="w-1/6 text-right">
                  (
                  <Amount
                    currency={invoiceDetail?.business.currency}
                    amount={invoiceDetail?.discount?.discountedAmount}
                  />
                  )
                </div>
              </div>
            )}
            {!!invoiceDetail?.tax?.taxRate && (
              <div className="flex pb-3 px-6">
                <div className="w-5/6 text-right">Tax:</div>
                <div className="w-1/6 text-right">
                  (
                  <Amount
                    currency={invoiceDetail?.business.currency}
                    amount={invoiceDetail?.tax?.taxAmount}
                  />
                  )
                </div>
              </div>
            )}
            <div className="flex pt-3 px-6 border-t-2 border-soft-grey">
              <div className="w-5/6 text-right font-semibold">Total:</div>
              <div className="w-1/6 text-right">
                <Amount
                  currency={invoiceDetail?.business.currency}
                  amount={invoiceDetail?.amountTotal}
                />
              </div>
            </div>

            {invoiceDetail?.payments.map((payment) => (
              <div className="flex pb-3 px-6" key={payment.id}>
                <div className="w-5/6 text-right">
                  Payment recorded on {formatReadableDate(payment.date)}
                </div>
                <div className="w-1/6 text-right">
                  <Amount currency={invoiceDetail?.business.currency} amount={payment.amount} />
                </div>
              </div>
            ))}
            <div className="flex py-3 px-6 border-t-2 border-soft-grey">
              <div className="w-5/6 text-right font-semibold">Amount due:</div>
              <div className="w-1/6 text-right">
                <Amount
                  currency={invoiceDetail?.business.currency}
                  amount={invoiceDetail?.amountDue}
                />
              </div>
            </div>
          </div>
          <div className="no-break">
            {invoiceDetail?.businessPaymentMethod && (
              <div className="p-6">
                <h3 className="text-xl font-semibold">Payment details:</h3>
                <div className="text-sm mt-1">
                  {invoiceDetail?.paymentMethod.name == BANK_DEPOSIT && (
                    <>
                      <h6 className="font-semibold mt-2">Bank Transfer:</h6>

                      <div>Bank: {invoiceDetail.businessPaymentMethod.details.bank}</div>
                      <div className="flex items-center gap-2">
                        Account Number: {invoiceDetail.businessPaymentMethod.details.accountNumber}
                        <span data-html2canvas-ignore="true" title="Copy to clipboard">
                          <CopyToClipboard
                            text={invoiceDetail.businessPaymentMethod.details.accountNumber}>
                            <Copy className="cursor-pointer text-primary w-6 h-6" />
                          </CopyToClipboard>
                        </span>
                      </div>
                      <div>
                        Account Name: {invoiceDetail.businessPaymentMethod.details.accountName}
                      </div>
                    </>
                  )}
                  {invoiceDetail?.paymentMethod.name == PAYMENT_LINK && (
                    <>
                      <h6 className="font-semibold mt-2">Payment link:</h6>
                      <a
                        href={invoiceDetail.businessPaymentMethod.details.paymentLink}
                        rel="noreferrer"
                        className="link underline"
                        target="_blank">
                        {invoiceDetail.businessPaymentMethod.details.paymentLink}
                      </a>
                    </>
                  )}
                </div>
              </div>
            )}
            {invoiceDetail?.note && (
              <div className="p-6">
                <h3 className="text-xl font-semibold">Notes/Terms:</h3>
                <div className="markdown markdown-table">
                  <Markdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[
                      rehypeRaw,
                      [
                        rehypeSanitize,
                        {
                          tagNames: [...defaultSchema.tagNames!, "u"]
                        }
                      ]
                    ]}>
                    {invoiceDetail?.note}
                  </Markdown>
                </div>
              </div>
            )}
            <div className="border-t-2 border-soft-grey p-6">
              <div className="flex justify-between">
                <div>
                  <p className="text-grey-60">{invoiceDetail?.business.phoneNumber}</p>
                  <p className="text-grey-60">{invoiceDetail?.business.email}</p>
                </div>
                <div className="text-right">
                  <p className="text-grey-60">{invoiceDetail?.business.address?.addressline1}</p>
                  <p className="text-grey-60">
                    {invoiceDetail?.business?.address.city},{" "}
                    {invoiceDetail?.business?.address?.state}
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center border-t-2 border-soft-grey p-6">
              <a target="_blank" rel="noreferrer" href="/" className="pt-8">
                <img src="/logo.svg" alt="Bloom Logo" className="w-32 sm:w-44" />
              </a>
              <p className="text-xs" data-html2canvas-ignore="true">
                Looking for a simple & smart way to monitor the health of your business?
                <br />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/"
                  className="pt-8 link font-normal underline">
                  Check Bloompulse out
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
