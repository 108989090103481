import { Invoice } from "components/Invoice/Invoice";
import { OrderInvoice } from "components/OrderInvoice/OrderInvoice";
import { AddressForm } from "components/Settings/AddressForm";
import { LogoUpload } from "components/Settings/LogoUpload";
import Dialog from "components/Shared/Dialog";
import LoadingComponent from "components/Shared/Loading";
import useOnboarding from "hooks/useOnboarding";
import { useOrderInvoice } from "hooks/useOrderInvoice/useOrderInvoice";
import useSettings from "hooks/useSettings";
import React, { useState } from "react";

const OrderInvoicePage = (): React.ReactElement => {
  const orderInvoiceProps = useOrderInvoice();
  const { getOnboardingAction, onboardingActionsIsFetching } = useOnboarding();
  const [isOpen, setIsOpen] = useState(!getOnboardingAction("CREATE_INVOICE"));
  const settingsProps = useSettings();

  return (
    <LoadingComponent loading={orderInvoiceProps.fetchingOrder || onboardingActionsIsFetching}>
      <OrderInvoice {...orderInvoiceProps} paymentMethod={orderInvoiceProps.paymentMethodProps} />
      <div className="h-0 w-0 overflow-hidden">
        <Invoice {...orderInvoiceProps.invoiceProps} />
      </div>
      <Dialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        titleClassName="bg-gradient-to-r from-primary to-secondary text-white text-center"
        title="Add your business information for invoices">
        <div className="flex flex-col items-center text-center">
          <p className="font-bold text-lg">Bussiness information</p>
          <AddressForm {...settingsProps} />
          <p className="font-bold text-lg mt-6 mb-3">Logo</p>
          <div className="mt-4 w-full lg:w-2/3">
            <LogoUpload {...settingsProps} />
          </div>
        </div>
      </Dialog>
    </LoadingComponent>
  );
};

export default OrderInvoicePage;
