import Autocomplete from "components/Shared/Autocomplete";
import Button from "components/Shared/Button";
import Error from "components/Shared/Error";
import TextInput from "components/Shared/TextInput";
import { findCountryDescription } from "helpers/currencies";
import React from "react";
import { SettingsProps } from "types";

export const AddressForm = ({
  handleContactFormSubmit,
  handleFormChange,
  businessForm,
  loaders,
  countries
}: SettingsProps) => {
  const countryDescription = findCountryDescription(countries, businessForm.country);

  return (
    <form onSubmit={handleContactFormSubmit} className="mt-4 w-full lg:w-2/3" data-testid="">
      <Error error={""} />
      <div>
        <TextInput
          data-testid="phoneNumber"
          type="phoneNumber"
          name="phoneNumber"
          onChange={handleFormChange}
          value={businessForm.phoneNumber}
          label="Business phone number"
        />
      </div>
      <div>
        <TextInput
          data-testid="email"
          type="email"
          name="email"
          onChange={handleFormChange}
          value={businessForm.email}
          label="Business email"
        />
      </div>
      <div>
        <TextInput
          data-testid="addressline1"
          type="addressline1"
          name="addressline1"
          onChange={handleFormChange}
          value={businessForm.addressline1}
          label="Address line 1"
        />
      </div>
      <div className="w-full flex space-x-4">
        <div className="w-1/2">
          <TextInput
            data-testid="city"
            type="city"
            name="city"
            onChange={handleFormChange}
            value={`${businessForm?.city}`}
            label="City"
          />
        </div>
        <div className="w-1/2">
          <TextInput
            data-testid="state"
            type="state"
            name="state"
            onChange={handleFormChange}
            value={`${businessForm?.state}`}
            label="State"
          />
        </div>
      </div>
      <div>
        <Autocomplete
          type="text"
          required
          name="country"
          onChange={handleFormChange}
          value={
            countryDescription
              ? `${countryDescription?.emoji} ${countryDescription?.name}`
              : undefined
          }
          label="Select address country"
          data-testid="country"
          data={countries.map((country) => ({
            id: country.code,
            name: `${country.emoji} ${country.name}`
          }))}
          placeholder="Start typing to filter countries"
          dataIdName="country"
        />
      </div>
      <div>
        <Button
          data-testid="submitButton"
          type="submit"
          loading={loaders.savingSettings}
          disabled={false}
          className="mt-4">
          Save changes
        </Button>
      </div>
    </form>
  );
};
