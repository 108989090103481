import { ProductItem } from "components/CreateEditOrder/Products/Products";
import Amount from "components/Shared/Amount";
import Button from "components/Shared/Button";
import Dropdown from "components/Shared/Dropdown";
import Error from "components/Shared/Error";
import Form from "components/Shared/Form";
import IconButton from "components/Shared/IconButton/IconButton";
import { InlineInput } from "components/Shared/InlineInput/InlineInput";
import Stepper from "components/Shared/Stepper";
import SubTitle from "components/Shared/SubTitle/SubTitle";
import Title from "components/Shared/Title";
import ArrowLeft from "icons/ArrowLeft";
import Copy from "icons/Copy";
import Delete from "icons/Delete";
import Edit from "icons/Edit";
import Kebeb from "icons/Kebeb";
import Plus from "icons/Plus";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CreateEditProductOfferingProps } from "types";
import { getOptionTotal } from "./offerings.utils";
import TextInput from "components/Shared/TextInput";
import Save from "icons/Save";

export const CreateEditProductOffering = (
  props: CreateEditProductOfferingProps
): React.ReactElement => {
  const {
    offeringId,
    handleFormChange,
    productOfferingForm,
    optionForm,
    error,
    loaders,
    formIsValid,
    handleOptionNameChange,
    products,
    handleOptionItemChange,
    handleRemoveItem,
    handleAddItem,
    handleDuplicateOption,
    handleRemoveOption,
    handleSaveOption,
    handleUpdateOption,
    optionIndexToBeUpdated,
    handleFormSubmit
  } = props;
  const isCreatingNewOffering = !offeringId;
  const [activeStep, setActiveStep] = useState("Name");

  return (
    <div className="bg-white rounded-lg py-form mobile-full-screen">
      <div className="form-xy pb-0">
        <Link
          to="/sales/offerings"
          className="font-bold text-sm inline-flex"
          data-testid="backLink">
          <span className="text-primary mr-1">
            <ArrowLeft />
          </span>
          Back to offerings
        </Link>
        <Title className="my-8 text-center">
          {isCreatingNewOffering ? "Create" : "Edit"} product offering
        </Title>
        <div>
          <Stepper
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            steps={["Name", "Setup options", "Add images", "Extras"]}
          />
        </div>
      </div>
      <div className="px-4 md:px-10">
        <Error error={error} />
      </div>
      {activeStep === "Name" && (
        <div>
          <SubTitle className="pt-6 text-grey-50">
            Give your offering a name that the customer will recognize
          </SubTitle>
          <div className="form-xy">
            <TextInput
              type="name"
              name="name"
              id="offeringName"
              value={productOfferingForm.name}
              onChange={handleFormChange}
              label="Offering name"
            />
          </div>
          <Form.Footer center>
            <Button
              type="button"
              disabled={!productOfferingForm.name}
              className="w-full md:w-60"
              onClick={() => {
                if (!isCreatingNewOffering) {
                  handleFormSubmit();
                }
                setActiveStep("Setup options");
              }}
              data-testid="continue">
              Continue
            </Button>
          </Form.Footer>
        </div>
      )}
      {activeStep === "Setup options" && (
        <div>
          <SubTitle className="pt-6 text-grey-50">
            Setup the options that are available for this product offering
          </SubTitle>
          {productOfferingForm.options.length > 0 && (
            <div className="form-xy py-5 border-soft-grey border-b-2">
              <h4 className="font-semibold text-xl mb-5 text-center">Offering Options</h4>
              {productOfferingForm.options.map((option, index) => (
                <div
                  className="flex justify-between bg-soft-grey rounded-lg px-6 py-4 mb-2"
                  key={index}
                  data-testid="option">
                  <div>
                    <p className="font-bold text-lg" data-testid="">
                      {option.name}
                    </p>
                    <p className="font-bold text-sm" data-testid="">
                      {option.items.map((item) => `${item.name} (x${item.quantity})`).join(", ")}
                    </p>
                    <span className="block md:hidden mt-2">
                      <b>
                        <Amount amount={getOptionTotal(option)} />
                      </b>
                    </span>
                  </div>

                  <div className="flex">
                    <span className="min-w-20 text-center hidden md:inline-flex">
                      <b>
                        <Amount amount={getOptionTotal(option)} />
                      </b>
                    </span>
                    <Dropdown
                      disabled={optionIndexToBeUpdated == index}
                      menu={[
                        {
                          text: "Edit",
                          icon: <Edit />,
                          onClick: () => handleUpdateOption(index)
                        },
                        {
                          text: "Duplicate",
                          icon: <Copy />,
                          onClick: () => handleDuplicateOption(index)
                        },
                        {
                          text: "Delete",
                          icon: <Delete />,
                          onClick: () => handleRemoveOption(index)
                        }
                      ]}>
                      <Kebeb />
                    </Dropdown>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="form-xy py-5 mt-2">
            <h4 className="font-semibold text-xl text-center">
              {optionIndexToBeUpdated == -1 ? "Add" : "Edit"} Option
            </h4>
            <div data-testid="products">
              <div className="relative form-x sm:pr-12 pl-0 pt-4 pb-2 " data-testid="option">
                <div>
                  <div className="w-full sm:w-4/5 flex justify-between pb-4 items-center">
                    <span className="flex items-center overflow-hidden max-w-[70%]">
                      <InlineInput
                        padSize={0}
                        defaultSize={13}
                        name="name"
                        id="name"
                        value={optionForm.name}
                        onChange={(e): void => {
                          handleOptionNameChange(e);
                        }}
                        placeholder="Untitled option *"
                      />
                    </span>
                  </div>
                  {optionForm.items.map((item, itemIndex) => (
                    <ProductItem
                      key={`${itemIndex}${item.variantId}`}
                      isInGroup
                      products={products}
                      product={item}
                      handleFormProductChange={(e): void => {
                        const productId =
                          e.target.name == "productId" ? e.target.value : item.productId;
                        const variantId =
                          e.target.name == "variantId" ? e.target.value : item.variantId;
                        handleOptionItemChange(
                          e,
                          itemIndex,
                          products.find(({ id }) => productId === id),
                          products
                            .find(({ id }) => productId === id)
                            ?.variants.find(({ id }) => variantId === id)
                        );
                      }}
                      handleFormProductVariantChange={(e): void => {
                        const productId =
                          e.target.name == "productId" ? e.target.value : item.productId;
                        const variantId =
                          e.target.name == "variantId" ? e.target.value : item.variantId;
                        handleOptionItemChange(
                          e,
                          itemIndex,
                          products.find(({ id }) => productId === id),
                          products
                            .find(({ id }) => item.productId === id)
                            ?.variants.find(({ id }) => variantId === id)
                        );
                      }}
                      handleRemoveProduct={(): void => handleRemoveItem(itemIndex)}
                      disabledRemove={optionForm.items.length == 1}
                    />
                  ))}
                </div>
              </div>
              <div className="my-4 pr-4 pl-4 w-full flex justify-between items-center">
                <button
                  type="button"
                  title="Add product"
                  className=" pl-2 cursor-pointer flex gap-1"
                  onClick={(): void => {
                    handleAddItem();
                  }}>
                  <span className="text-primary">
                    <Plus height="20" width="20" />
                  </span>
                  Add product
                </button>
                <IconButton
                  loading={loaders.savingOffering}
                  type="submit"
                  disabled={loaders.savingOffering || !formIsValid}
                  className=""
                  onClick={handleSaveOption}
                  icon={<Save />}
                  data-testid="continue">
                  Save option
                </IconButton>
              </div>
            </div>
          </div>
          <div className="border-soft-grey border-t flex justify-end py-4 px-14">
            <span className="text-xl font-semibold">
              <span className="">Total: </span>
              <Amount amount={getOptionTotal(optionForm)} />
            </span>
          </div>
          <Form.Footer>
            <Button
              type="button"
              className="w-auto min-w-32 md:w-40 bg-dark-grey"
              onClick={() => setActiveStep("Name")}
              gradient={false}
              data-testid="prev">
              Go back
            </Button>
            <Button
              loading={loaders.savingOffering}
              type="submit"
              // disabled={loaders.savingOffering || productOfferingForm.options.length == 0}
              className="w-auto min-w-32 md:w-40"
              // onClick={handleSaveOption}
              disabled
              data-testid="continue">
              Continue
            </Button>
          </Form.Footer>
        </div>
      )}
    </div>
  );
};
