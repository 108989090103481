import { AddOnForm } from "components/ProductConfiguration/AddOns/AddonForm";
import Accordion from "components/Shared/Accordion";
import Autocomplete from "components/Shared/Autocomplete";
import { AutosaveButton } from "components/Shared/AutosaveButton/AutosaveButton";
import Error from "components/Shared/Error";
import IconButton from "components/Shared/IconButton";
import Tag from "components/Shared/Tag/Tag";
import ArrowLeft from "icons/ArrowLeft";
import ArrowRight from "icons/ArrowRight";
import Plus from "icons/Plus";
import React, { useState } from "react";
import { TemplateAddOnProps } from "types";

const AddOns = (props: TemplateAddOnProps): React.ReactElement => {
  const {
    addOnProps,
    handleAddAddOn,
    handleRemoveAddOn,
    templateVariantAddOns,
    templateAddOns,
    loaders,
    error,
    hasUnsavedChanges,
    name
  } = props;
  const { businessAddOns } = addOnProps;
  const [activeKey, setActiveKey] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <form className="pb-40" data-testid="addOnForm">
        <div className="bg-white md:rounded-lg px-4 md:px-6 py-4 mt-4 md:h-auto">
          <Error error={error} />
          <p className="text-grey-50 px-4 mb-4 md:px-0 mt-2 sm:mt-0" data-testid="componentDesc">
            Add extras to your variants that you sell along with the product.
          </p>
          <div>
            <Autocomplete
              type="text"
              name="addOns"
              onDataSelected={(data) => {
                if (data) {
                  handleAddAddOn(data.id);
                }
              }}
              value=""
              label="Add Add-on"
              data-testid="addOns"
              dataIdName="addOnId"
              data={
                businessAddOns
                  .filter(
                    (businessAddOn) =>
                      !templateAddOns.find((addOn) => businessAddOn.id === addOn.businessAddOnId)
                  )
                  .map((item) => ({ id: item.id, name: item.name })) || []
              }
              placeholder="Start typing to filter business add-ons"
              defaultData={
                <span className="text-grey-50 text-sm">
                  Can’t see what you are looking for?&nbsp;{" "}
                  <button
                    type="button"
                    className="link inline-flex"
                    onClick={() => setIsOpen(true)}>
                    <b>Create a new add-on</b>
                  </button>
                </span>
              }
            />
            <button
              type="button"
              className="flex gap-1 items-center link cursor-pointer mb-2"
              onClick={() => setIsOpen(true)}>
              <Plus />
              <b>Create a new add-on</b>
            </button>
            <div>
              <p className="font-bold">Add-ons in use</p>
              <div className="flex mt-4 flex-wrap" data-testid="productAddOns">
                {templateAddOns.map((addOn) => (
                  <Tag
                    key={addOn.id}
                    name={addOn.name}
                    onRemove={() => handleRemoveAddOn(addOn.id)}
                  />
                ))}
              </div>
            </div>
          </div>
          <p className="text-grey-50 px-4 mb-4 md:px-0 mt-2 sm:mt-6" data-testid="">
            {templateAddOns.length > 0
              ? `Set the add-on costs for ${name} variants.`
              : "No add-ons in use."}
          </p>
          {templateVariantAddOns.map(
            (templateVariantAddOn, templateVariantAddOnIndex): React.ReactElement => (
              <Accordion
                key={templateVariantAddOn.variantId}
                itemId={templateVariantAddOn.variantId}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                header={
                  <div className="inline-flex items-center justify-between flex-1 pr-6">
                    <span>
                      <b data-testid="variantName">{templateVariantAddOn.variantName}</b>
                    </span>
                  </div>
                }>
                <div className="flex flex-col">
                  {templateVariantAddOn.addOnCosts.map((addOnCost) => (
                    <div
                      className="flex justify-between items-center pt-2 px-2 md:px-4  border-b border-grey-30"
                      key={addOnCost.businessAddOnId}
                      data-testid="addOnCost">
                      <span data-testid="addOnName" className="flex-1 my-3">
                        {addOnCost.name}
                      </span>
                      <div className="flex-1 max-w-xs"></div>
                    </div>
                  ))}
                  <div className="flex justify-between items-center py-2 px-4 border-grey-30 bg-soft-grey text-primary">
                    <div>
                      {templateVariantAddOnIndex > 0 && (
                        <IconButton
                          size="sm"
                          onClick={() =>
                            setActiveKey(
                              templateVariantAddOns[templateVariantAddOnIndex - 1].variantId
                            )
                          }
                          icon={<ArrowLeft />}
                          data-testid="prev"
                          outlined>
                          Previous
                        </IconButton>
                      )}
                    </div>
                    <div>
                      {templateVariantAddOnIndex < templateVariantAddOns.length - 1 && (
                        <IconButton
                          size="sm"
                          onClick={() =>
                            setActiveKey(
                              templateVariantAddOns[templateVariantAddOnIndex + 1].variantId
                            )
                          }
                          iconEnd={<ArrowRight />}
                          data-testid="next"
                          outlined>
                          Next variant
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
              </Accordion>
            )
          )}
        </div>
        <div className="fixed left-0 right-0 bottom-0 bg-white sm:px-12 xl:px-40 py-6 text-center md:text-right z-20">
          <AutosaveButton
            disabled={!hasUnsavedChanges}
            hasChanges={hasUnsavedChanges}
            type="submit"
            loading={loaders.savingAddOns}
            data-testid="submitButton"
            className="w-60"
          />
        </div>
      </form>
      <AddOnForm
        {...addOnProps}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleFormSubmit={addOnProps.handleProductFormSubmit}
      />
    </div>
  );
};

export default AddOns;
