import { useInvoice } from "hooks/useInvoice/useInvoice";
import LoadingComponent from "components/Shared/Loading";
import { Invoice } from "components/Invoice/Invoice";
import React, { useEffect } from "react";
import { track } from "helpers/analytics";
import { SegmentEvent } from "types/enum";
import { Helmet } from "react-helmet-async";

const ViewInvoicePage = (): React.ReactElement => {
  const invoiceProps = useInvoice();

  useEffect(() => {
    if (invoiceProps.invoiceDetail) {
      track(SegmentEvent.INVOICE_VIEWED, {
        business: invoiceProps.invoiceDetail.business.name,
        customer: invoiceProps.invoiceDetail.customer.name,
        orderId: invoiceProps.invoiceDetail.orderId,
        invoiceId: invoiceProps.invoiceDetail.id
      });
    }
  }, [invoiceProps.invoiceDetail]);

  return (
    <div className="w-screen h-screen">
      <Helmet>
        {/* Page Title */}
        <title>{`${invoiceProps?.invoiceDetail?.business.name} Invoice`}</title>

        {/* Meta Description */}
        <meta
          name="description"
          content={`Invoice #${invoiceProps.invoiceDetail?.ref} for ${
            invoiceProps.invoiceDetail?.customer.name
          } total ₦${invoiceProps.invoiceDetail?.amountTotal.toLocaleString()}`}
        />

        {/* Open Graph Meta Tags (for previews on social media) */}
        <meta
          property="og:title"
          content={`${invoiceProps?.invoiceDetail?.business.name} Invoice`}
        />
        <meta
          property="og:description"
          content={`Invoice #${invoiceProps.invoiceDetail?.ref} for ${
            invoiceProps.invoiceDetail?.customer.name
          } total ₦${invoiceProps.invoiceDetail?.amountTotal.toLocaleString()}`}
        />
        {invoiceProps.invoiceDetail?.business.logoUrl && (
          <meta property="og:image" content={invoiceProps.invoiceDetail?.business.logoUrl} />
        )}
        <meta property="og:type" content="website" />
      </Helmet>
      <LoadingComponent loading={invoiceProps.loaders.fetchingInvoice}>
        <Invoice {...invoiceProps} />
      </LoadingComponent>
    </div>
  );
};

export default ViewInvoicePage;
