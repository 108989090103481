import React from "react";
import { CreateEditProductVariantProps, FormChangeEvent } from "types";
import { Link } from "react-router-dom";
import ArrowLeft from "icons/ArrowLeft";
import Error from "components/Shared/Error";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import Title from "components/Shared/Title";
import Amount from "components/Shared/Amount";
import IconButton from "components/Shared/IconButton";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";

export const CreateEditProductVariant = (
  props: CreateEditProductVariantProps
): React.ReactElement => {
  const {
    productVariantForm,
    handleProductVariantFormChange,
    handleProductVariantFormSubmit,
    formIsValid,
    error,
    loaders,
    variantId,
    backURL,
    type
  } = props;
  const isCreatingNewVariant = !variantId;

  const targetMargin = +productVariantForm.targetMargin;
  const costPrice = +productVariantForm.costPrice;
  const defaultSellingPrice = +productVariantForm.defaultSellingPrice;

  const suggestedSellingPrice = (costPrice * (1 + targetMargin / 100)).toFixed(0);

  const currentMargin = Math.max(
    ((+defaultSellingPrice - costPrice) / Math.max(+defaultSellingPrice, 1)) * 100,
    0
  ).toFixed(0);

  return (
    <div className="bg-white rounded-lg p-4 lg:p-10 mobile-full-screen">
      <Link to={backURL} className="font-bold text-sm flex" data-testid="backLink">
        <span className="text-primary mr-1">
          <ArrowLeft />
        </span>
        Back to variants
      </Link>
      <Title className="text-center mt-6 md:mt-0">
        <>{isCreatingNewVariant ? "Add new" : "Edit"} variant</>
      </Title>
      <p className="text-grey-50 text-center pb-4">
        Variants are different attributes of your product, such as colours, sizes, pricing,
        materials, or any other specific features.
      </p>
      <form
        onSubmit={handleProductVariantFormSubmit}
        className="mt-10 mx-6 md:mx-12 xl:mx-40"
        data-testid="productVariantForm">
        <Error error={error} />

        <div>
          <TextInput
            type="text"
            name="name"
            onChange={handleProductVariantFormChange}
            value={productVariantForm.name}
            required
            label="Name"
            data-testid="nameInput"
          />
        </div>
        {type == "Product" && (
          <>
            <div>
              <TextInput
                type="number"
                name="targetMargin"
                onChange={handleProductVariantFormChange}
                value={productVariantForm.targetMargin}
                required={true}
                label="Target Margin"
                data-testid="targetMargin"
                min={0}
                step={1}
              />
            </div>
            <div className="mb-4">
              <p>
                Cost Price:{" "}
                <b>
                  <Amount amount={productVariantForm.costPrice} />
                </b>
              </p>
              <p className="mt-2">
                Current Margin: <b>{currentMargin}%</b>
              </p>
              <div className="flex items-center justify-between">
                <p className="flex items-center">
                  Suggested Selling Price:{" "}
                  <b>
                    <Amount amount={+suggestedSellingPrice} />
                  </b>
                  <InfoTooltip>
                    The suggested selling price is calculated based on the cost price and your
                    target margin
                  </InfoTooltip>
                </p>
                <IconButton
                  className="!px-8"
                  outlined
                  onClick={() =>
                    handleProductVariantFormChange({
                      target: {
                        name: "defaultSellingPrice",
                        value: suggestedSellingPrice
                      }
                    } as FormChangeEvent)
                  }>
                  Apply
                </IconButton>
              </div>
            </div>
            <div>
              <TextInput
                type="number"
                name="defaultSellingPrice"
                onChange={handleProductVariantFormChange}
                value={productVariantForm.defaultSellingPrice}
                required={true}
                label={
                  <>
                    Selling Price <Amount amount={+productVariantForm.defaultSellingPrice} />
                  </>
                }
                data-testid="defaultSellingPrice"
                min={0}
              />
            </div>
          </>
        )}
        <Button
          type="submit"
          data-testid="submitButton"
          disabled={!formIsValid}
          loading={loaders.savingVariant}
          className="w-full mt-6">
          Save
        </Button>
      </form>
    </div>
  );
};
