import React, { useState } from "react";
import { CreateEditProductProps } from "types";
import { Link } from "react-router-dom";
import ArrowLeft from "icons/ArrowLeft";
import Error from "components/Shared/Error";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import Title from "components/Shared/Title";
import Stepper from "components/Shared/Stepper";
import IconButton from "components/Shared/IconButton";
import Plus from "icons/Plus";
import Cancel from "icons/Cancel";
import Edit from "icons/Edit";
import Amount from "components/Shared/Amount";
import Form from "components/Shared/Form";

const CreateProduct = (props: CreateEditProductProps): React.ReactElement => {
  const {
    productForm,
    handleFormChange,
    handleFormSubmit,
    formIsValid,
    error,
    loaders,
    handleFormVariantChange,
    handleAddVariant,
    handleRemoveVariant
  } = props;
  const [activeStep, setActiveStep] = useState("Product name");

  return (
    <div className="bg-white rounded-lg py-form">
      <div className="form-xy">
        <Link to="/products" className="font-bold text-sm	inline-flex" data-testid="backLink">
          <span className="text-primary mr-1">
            <ArrowLeft />
          </span>
          Back to products
        </Link>
        <Title className="my-8 text-center">Add new product</Title>
        <Stepper
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          steps={["Product name", "Product variants", "Submit"]}
        />
      </div>
      <form onSubmit={handleFormSubmit} data-testid="productForm">
        <Error error={error} />
        {activeStep === "Product name" && (
          <div data-testid="productName">
            <div className="form-center">
              <div>
                <TextInput
                  type="text"
                  name="name"
                  onChange={handleFormChange}
                  value={productForm.name}
                  required
                  label="Name"
                  data-testid="nameInput"
                />
              </div>
            </div>
            <Form.Footer center>
              <Button
                type="button"
                disabled={!productForm.name}
                onClick={(): void => setActiveStep("Product variants")}
                className="w-60"
                data-testid="continue">
                Continue
              </Button>
            </Form.Footer>
          </div>
        )}
        {activeStep === "Product variants" && (
          <div>
            <p className="form-xy pt-0 text-grey-50 text-center pb-4">
              Variants are different attributes of your product, such as colours, sizes, pricing,
              materials, or any other specific features.
            </p>
            <div className="form-xy pr-12 md:pr-20 pt-0">
              <div data-testid="productVariants">
                {productForm.variants.map((variant, index) => (
                  <div className="flex space-x-2 relative" key={index} data-testid="productVariant">
                    <TextInput
                      type="text"
                      name="name"
                      onChange={(e): void => handleFormVariantChange(e, index)}
                      value={variant.name}
                      required
                      label="Variant Name"
                      data-testid="variantName"
                    />
                    <TextInput
                      type="number"
                      name="defaultSellingPrice"
                      onChange={(e): void => handleFormVariantChange(e, index)}
                      value={variant.defaultSellingPrice}
                      label={
                        <>
                          Selling Price <Amount amount={+variant.defaultSellingPrice} />
                        </>
                      }
                      data-testid="variantDefaultSellingPrice"
                    />
                    {index > 0 && (
                      <button
                        onClick={(): void => handleRemoveVariant(index)}
                        type="button"
                        className="mb-6 absolute left-full top-0 bottom-0 text-danger"
                        data-testid="removeVariant">
                        <Cancel />
                      </button>
                    )}
                  </div>
                ))}
                <IconButton
                  onClick={handleAddVariant}
                  icon={<Plus />}
                  data-testid="addVariant"
                  outlined>
                  Add new variant
                </IconButton>
              </div>
            </div>
            <Form.Footer spaceBetween>
              <Button
                type="button"
                onClick={(): void => setActiveStep("Product name")}
                className="w-auto min-w-32 sm:w-60 !bg-black"
                gradient={false}
                data-testid="back">
                Go back
              </Button>
              <Button
                type="button"
                disabled={!formIsValid}
                onClick={(): void => setActiveStep("Submit")}
                className="w-auto min-w-32 sm:w-60"
                data-testid="continue">
                Continue
              </Button>
            </Form.Footer>
          </div>
        )}
        {activeStep === "Submit" && (
          <div className="text-dark-grey" data-testid="submit">
            <div className="form-xy pt-0">
              <div className="flex justify-between">
                <p className="">Product name</p>
                <button
                  onClick={(): void => setActiveStep("Product name")}
                  type="button"
                  className="text-primary inline-flex items-center"
                  data-testid="editProductName">
                  <Edit /> <span className="ml-2">Edit</span>
                </button>
              </div>
              <hr className="flex-1 h-0.5 my-2" />
              <p className="font-bold" data-testid="productName">
                {productForm.name}
              </p>
              <div className="flex justify-between mt-8">
                <p className="">Variants</p>
                <button
                  onClick={(): void => setActiveStep("Product variants")}
                  type="button"
                  className="text-primary inline-flex items-center"
                  data-testid="editProductVariants">
                  <Edit /> <span className="ml-2">Edit</span>
                </button>
              </div>
              <hr className="flex-1 h-0.5 my-2" />
              {productForm.variants.map((variant, index) => (
                <span key={index} className="flex mt-3" data-testid="productVariantItem">
                  <p className="font-bold w-2/5">{variant.name}</p>
                  <Amount amount={+variant.defaultSellingPrice} />
                </span>
              ))}
            </div>
            <Form.Footer spaceBetween>
              <Button
                type="button"
                onClick={(): void => setActiveStep("Product variants")}
                className="w-auto min-w-32 sm:w-60 !bg-black"
                gradient={false}
                data-testid="back">
                Go back
              </Button>
              <Button
                type="submit"
                disabled={!formIsValid}
                loading={loaders.savingProduct}
                data-testid="submitButton"
                className="w-auto min-w-32 sm:w-60">
                Submit product
              </Button>
            </Form.Footer>
          </div>
        )}
      </form>
    </div>
  );
};

export default CreateProduct;
