import { RangeInput } from "components/Shared/RangeInput/RangeInput";
import Select from "components/Shared/Select";
import React from "react";
import { AllocateExpenseProps } from "types";

const AllocateExpense = (props: AllocateExpenseProps): React.ReactElement => {
  const {
    expenseForm,
    businessActivities,
    handleActivityAllocationChange,
    expensesActivityAllocation,
    reusedExpense,
    handleReuseExpenseAllocation
  } = props;

  return (
    <div>
      <div className="sm:px-16">
        <p className="text-grey-60 text-sm text-center mb-2">
          All allocations have to be 100% in total.
        </p>
        <Select
          className=""
          id="reuse"
          name="reuse"
          value={reusedExpense?.id || ""}
          onChange={(e): void => handleReuseExpenseAllocation(e.target.value)}
          label="Reuse a previous expense allocation"
          data-testid="reuse">
          <option value="" disabled>
            Select expense
          </option>
          {expensesActivityAllocation.map((expense) => (
            <option value={expense.id} key={expense.id}>
              {expense.name}
            </option>
          ))}
        </Select>
      </div>
      {businessActivities.map((activity) => {
        const expenseAllocation =
          expenseForm.activities?.find((allocation) => allocation.activityId == activity.id)
            ?.allocation || 0;
        return (
          <div key={activity.id} className="mb-4 ">
            <p>{activity.name}</p>
            <div className="flex js">
              <RangeInput
                className="w-full"
                type="range"
                id="activityAllocation"
                name={activity.id}
                onChange={handleActivityAllocationChange}
                disabled={!activity.isProductionActivity}
                value={expenseAllocation}
              />
              <p className="w-16 text-right">{expenseAllocation}%</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AllocateExpense;
