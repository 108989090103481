import { flag } from "country-emoji";
import { CountryDescription } from "./countries";

export enum CurrencyCode {
  NGN = "NGN",
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  JPY = "JPY",
  CAD = "CAD",
  AUD = "AUD",
  CHF = "CHF",
  CNY = "CNY",
  SEK = "SEK",
  NZD = "NZD",
  MXN = "MXN",
  BRL = "BRL",
  RUB = "RUB",
  INR = "INR",
  KRW = "KRW",
  TRY = "TRY",
  ZAR = "ZAR",
  ILS = "ILS",
  PHP = "PHP",
  CLP = "CLP",
  COP = "COP",
  PEN = "PEN",
  ARS = "ARS",
  ISK = "ISK",
  UAH = "UAH",
  THB = "THB",
  VND = "VND",
  MYR = "MYR",
  BGN = "BGN",
  DKK = "DKK",
  HKD = "HKD",
  NOK = "NOK",
  PLN = "PLN",
  IDR = "IDR",
  GHS = "GHS",
  KES = "KES"
}

export type CurrencyDescription = {
  name: string;
  code: CurrencyCode;
  symbol: string;
  emoji: string | undefined;
};

export const currrencyDescriptions: CurrencyDescription[] = [
  {
    code: CurrencyCode.NGN,
    name: "Nigerian Naira",
    symbol: "₦",
    emoji: flag("ng")
  },
  {
    code: CurrencyCode.USD,
    name: "US Dollar",
    symbol: "$",
    emoji: flag("us")
  },
  {
    code: CurrencyCode.EUR,
    name: "Euro",
    symbol: "€",
    emoji: flag("eu")
  },
  {
    code: CurrencyCode.GBP,
    name: "British Pound",
    symbol: "£",
    emoji: flag("gb")
  },
  {
    code: CurrencyCode.JPY,
    name: "Japanese Yen",
    symbol: "¥",
    emoji: flag("jp")
  },
  {
    code: CurrencyCode.CAD,
    name: "Canadian Dollar",
    symbol: "CA$",
    emoji: flag("ca")
  },
  {
    code: CurrencyCode.AUD,
    name: "Australian Dollar",
    symbol: "AU$",
    emoji: flag("au")
  },
  {
    code: CurrencyCode.CHF,
    name: "Swiss Franc",
    symbol: "CHF",
    emoji: flag("ch")
  },
  {
    code: CurrencyCode.CNY,
    name: "Chinese Yuan",
    symbol: "CN¥",
    emoji: flag("cn")
  },
  {
    code: CurrencyCode.SEK,
    name: "Swedish Krona",
    symbol: "SEK",
    emoji: flag("se")
  },
  {
    code: CurrencyCode.NZD,
    name: "New Zealand Dollar",
    symbol: "NZ$",
    emoji: flag("nz")
  },
  {
    code: CurrencyCode.MXN,
    name: "Mexican Peso",
    symbol: "MX$",
    emoji: flag("mx")
  },
  {
    code: CurrencyCode.BRL,
    name: "Brazilian Real",
    symbol: "R$",
    emoji: flag("br")
  },
  {
    code: CurrencyCode.RUB,
    name: "Russian Ruble",
    symbol: "₽",
    emoji: flag("ru")
  },
  {
    code: CurrencyCode.INR,
    name: "Indian Rupee",
    symbol: "₹",
    emoji: flag("in")
  },
  {
    code: CurrencyCode.KRW,
    name: "South Korean Won",
    symbol: "₩",
    emoji: flag("kr")
  },
  {
    code: CurrencyCode.TRY,
    name: "Turkish Lira",
    symbol: "TRY",
    emoji: flag("tr")
  },
  {
    code: CurrencyCode.ZAR,
    name: "South African Rand",
    symbol: "R",
    emoji: flag("za")
  },
  {
    code: CurrencyCode.ILS,
    name: "Israeli New Shekel",
    symbol: "₪",
    emoji: flag("il")
  },
  {
    code: CurrencyCode.PHP,
    name: "Philippine Peso",
    symbol: "₱",
    emoji: flag("ph")
  },
  {
    code: CurrencyCode.CLP,
    name: "Chilean Peso",
    symbol: "CLP",
    emoji: flag("cl")
  },
  {
    code: CurrencyCode.COP,
    name: "Colombian Peso",
    symbol: "COP",
    emoji: flag("co")
  },
  {
    code: CurrencyCode.PEN,
    name: "Peruvian Sol",
    symbol: "PEN",
    emoji: flag("pe")
  },
  {
    code: CurrencyCode.ARS,
    name: "Argentine Peso",
    symbol: "ARS",
    emoji: flag("ar")
  },
  {
    code: CurrencyCode.ISK,
    name: "Icelandic Króna",
    symbol: "ISK",
    emoji: flag("is")
  },
  {
    code: CurrencyCode.UAH,
    name: "Ukrainian Hryvnia",
    symbol: "UAH",
    emoji: flag("ua")
  },
  {
    code: CurrencyCode.THB,
    name: "Thai Baht",
    symbol: "฿",
    emoji: flag("th")
  },
  {
    code: CurrencyCode.VND,
    name: "Vietnamese Đồng",
    symbol: "₫",
    emoji: flag("vn")
  },
  {
    code: CurrencyCode.MYR,
    name: "Malaysian Ringgit",
    symbol: "MYR",
    emoji: flag("my")
  },
  {
    code: CurrencyCode.BGN,
    name: "Bulgarian Lev",
    symbol: "BGN",
    emoji: flag("bg")
  },
  {
    code: CurrencyCode.DKK,
    name: "Danish Krone",
    symbol: "DKK",
    emoji: flag("dk")
  },
  {
    code: CurrencyCode.HKD,
    name: "Hong Kong Dollar",
    symbol: "HKD",
    emoji: flag("hk")
  },
  {
    code: CurrencyCode.NOK,
    name: "Norwegian Krone",
    symbol: "NOK",
    emoji: flag("no")
  },
  {
    code: CurrencyCode.PLN,
    name: "Polish Złoty",
    symbol: "PLN",
    emoji: flag("pl")
  },
  {
    code: CurrencyCode.IDR,
    name: "Indonesian Rupiah",
    symbol: "IDR",
    emoji: flag("id")
  },
  {
    code: CurrencyCode.GHS,
    name: "Ghanaian Cedi",
    symbol: "₵",
    emoji: flag("gh")
  },
  {
    code: CurrencyCode.KES,
    name: "Kenyan Shilling",
    symbol: "Ksh",
    emoji: flag("ke")
  }
];

export const findCurrencyDescription = (currencyCode: string): CurrencyDescription | undefined => {
  return currrencyDescriptions.find(
    (currencyDescription) => currencyDescription.code === (currencyCode as CurrencyCode)
  );
};

export const findCountryDescription = (
  countries: CountryDescription[],
  countryCode: string
): CountryDescription | undefined => {
  return countries.find((country) => country.code === countryCode);
};
