import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "config/api";
import { FETCH_FAILED, REQUEST_SUCCESSFUL } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { AlertType } from "types/enum";
import {
  FormChangeEvent,
  ProductDetailVariant,
  ProductOffering,
  ProductOfferingForm,
  ProductOfferingFormOption,
  ProductOfferingFormOptionItem,
  ProductWithVariants,
  Response
} from "types";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { removeAtIndex, replaceAtIndex } from "helpers/array";
import { extractErrorMessage } from "helpers/api";
import { getOfferingRequestData, resetItem } from "./useProductOffering.utils";
import { isNotEmpty, isNumber } from "helpers/validate";

export const useProductOffering = () => {
  const { showAlert } = useAlert();
  const { offeringId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const defaultForm: ProductOfferingForm = {
    name: "",
    options: []
  };

  const defaultItem: ProductOfferingFormOptionItem = {
    variantId: "",
    productId: "",
    quantity: "1",
    unitPrice: "",
    name: ""
  };

  const defaultOption: ProductOfferingFormOption = {
    name: "",
    items: [defaultItem]
  };

  const [error, setError] = useState("");
  const [productOfferingForm, setProductOfferingForm] = useState<ProductOfferingForm>(defaultForm);
  const [optionForm, setOptionForm] = useState<ProductOfferingFormOption>(defaultOption);
  const [formIsValid, setFormIsValid] = useState(false);
  const [optionIndexToBeUpdated, setOptionIndexToBeUpdated] = useState<number>(-1);

  const productOfferingQuery = useQuery({
    queryKey: ["product_offering", offeringId],
    enabled: !!offeringId,
    queryFn: async (): Promise<ProductOffering | undefined> => {
      try {
        const json: Response<ProductOffering> = await api
          .get(`product-offering/${offeringId}`)
          .json();
        if (json.code === 200) {
          setProductOfferingForm({
            name: json.data.name,
            options: json.data.options.map((option) => ({
              name: option.name,
              items: option.items.map((item) => ({
                quantity: item.quantity.toString(),
                variantId: item.variant.id,
                unitPrice: item.unitPrice.toString(),
                productId: item.product.id,
                name: `${item.product.name}-${item.variant.name}`
              }))
            }))
          });
          return json.data;
        }
      } catch (err) {
        showAlert(AlertType.DANGER, FETCH_FAILED);
        console.error(err);
      }
    }
  });

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;

    setProductOfferingForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleRemoveOption = (optionIndex: number): void => {
    setProductOfferingForm((prev) => {
      const options = removeAtIndex(prev.options, optionIndex);
      return {
        ...prev,
        options
      };
    });
    setError("");
    handleFormSubmit();
  };

  const handleRemoveItem = (itemIndex: number): void => {
    setOptionForm((prev) => {
      const items = removeAtIndex(prev.items, itemIndex);
      return {
        ...prev,
        items
      };
    });
    setError("");
  };

  const createOfferingMutation = useMutation({
    mutationFn: async () => {
      const json: Response<string> = await api
        .post("product-offering", { json: getOfferingRequestData(productOfferingForm) })
        .json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        navigate(`/sales/offerings/${json.data}?step=Setup options`);
      }
      showAlert(AlertType.SUCCESS, REQUEST_SUCCESSFUL);
      return isSuccessfull;
    },
    onError: async (error: Error) => {
      showAlert(AlertType.DANGER, await extractErrorMessage(error));
    }
  });

  const updateOfferingMutation = useMutation({
    mutationFn: async () => {
      const json: Response<string> = await api
        .put(`product-offering/${offeringId}`, {
          json: getOfferingRequestData(productOfferingForm)
        })
        .json();
      const isSuccessfull = json.code === 200;
      showAlert(AlertType.SUCCESS, REQUEST_SUCCESSFUL);
      return isSuccessfull;
    },
    onError: async (error: Error) => {
      showAlert(AlertType.DANGER, await extractErrorMessage(error));
    }
  });

  const handleFormSubmit = async (): Promise<void> => {
    if (offeringId) {
      updateOfferingMutation.mutate();
    } else {
      createOfferingMutation.mutate();
    }
  };

  const handleOptionNameChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;

    setOptionForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
    setError("");
  };

  const handleOptionItemChange = (
    event: FormChangeEvent,
    itemIndex: number,
    product: ProductWithVariants | undefined,
    variant: ProductDetailVariant | undefined
  ): void => {
    const { name, value } = event.target;
    console.log(optionForm.name, name, product);
    setOptionForm((prev) => ({
      ...prev,
      name: !prev.name && name == "productId" && product?.name ? product.name : prev.name,
      items: replaceAtIndex(
        prev.items,
        {
          ...prev.items[itemIndex],
          ...resetItem(event, product, variant),
          [name]: value
        },
        itemIndex
      )
    }));
    setError("");
  };

  const handleSaveOption = (): void => {
    if (!formIsValid) {
      return;
    }
    setProductOfferingForm((prev) => {
      const options =
        optionIndexToBeUpdated >= 0
          ? replaceAtIndex(prev.options, optionForm, optionIndexToBeUpdated)
          : [...prev.options, optionForm];
      return {
        ...prev,
        options
      };
    });
    setOptionForm(defaultOption);
    setOptionIndexToBeUpdated(-1);

    if (productOfferingForm.name) {
      handleFormSubmit();
    }
  };

  const handleAddItem = (): void => {
    setOptionForm((prev) => ({
      ...prev,
      items: [...prev.items, defaultItem]
    }));
  };

  const handleUpdateOption = (optionIndex: number): void => {
    setOptionForm(productOfferingForm.options[optionIndex]);
    setOptionIndexToBeUpdated(optionIndex);
  };

  const handleDuplicateOption = (optionIndex: number): void => {
    setProductOfferingForm((prev) => {
      return {
        ...prev,
        options: [
          ...prev.options,
          { ...prev.options[optionIndex], name: `${prev.options[optionIndex].name} Copy` }
        ]
      };
    });
    handleFormSubmit();
  };

  useEffect(() => {
    const optionIsValid =
      optionForm.items.length > 0 &&
      optionForm.items.reduce((prev, curr) => {
        return (
          prev && isNotEmpty(curr.variantId) && isNumber(curr.quantity) && isNumber(curr.unitPrice)
        );
      }, true);

    setFormIsValid(isNotEmpty(optionForm.name) && optionIsValid);
  }, [optionForm]);

  return {
    loaders: {
      fetchingOffering: productOfferingQuery.isFetching,
      savingOffering: createOfferingMutation.isPending || updateOfferingMutation.isPending
    },
    productOffering: productOfferingQuery.data || [],
    productOfferingForm,
    handleFormSubmit,
    handleFormChange,
    handleRemoveItem,
    handleRemoveOption,
    handleOptionNameChange,
    handleOptionItemChange,
    formIsValid,
    handleDuplicateOption,
    handleUpdateOption,
    handleAddItem,
    handleSaveOption,
    offeringId,
    error,
    optionForm,
    optionIndexToBeUpdated
  };
};

export type UseProductOfferingType = ReturnType<typeof useProductOffering>;
