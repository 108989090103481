import { Sales } from "components/Sales/Sales";
import LoadingComponent from "components/Shared/Loading";
import useOrder from "hooks/useOrder";
import React, { useEffect } from "react";

export const SalesOverviewPage = (): React.ReactElement => {
  const props = useOrder("UNPAID");
  const { dateRange, handleGetOrders, loaders } = props;

  useEffect(() => {
    handleGetOrders();
  }, [dateRange]);
  return (
    <LoadingComponent loading={loaders.fetchingOrder && !props.ordersSummary}>
      <Sales {...props} />
    </LoadingComponent>
  );
};
