import { FormChangeEvent, OrderInvoiceProps } from "types";
import React from "react";
import { Link } from "react-router-dom";
import ArrowLeft from "icons/ArrowLeft";
import Title from "components/Shared/Title";
import { DropdownButton } from "components/Shared/DropdownButton/DropdownButton";
import Caret from "icons/Caret";
import Button from "components/Shared/Button";
import Amount from "components/Shared/Amount";
import TextInput from "components/Shared/TextInput";
import { InlineInput } from "components/Shared/InlineInput/InlineInput";
import DateInput from "components/Shared/DateInput";
import Radio from "components/Shared/Radio";
import { BANK_DEPOSIT, PAYMENT_LINK } from "constants/general";
import Edit from "icons/Edit";
import { PaymentMethodsDialog } from "components/PaymentMethods/PaymentMethodsDialog";
import Initials from "components/Shared/Initials/Initials";
import { useAppState } from "config/store";
import { isEmail, isNotEmpty } from "helpers/validate";
import Dialog from "components/Shared/Dialog";
import IconButton from "components/Shared/IconButton";
import { formatReadableDate } from "helpers/date";
import Plus from "icons/Plus";
import { MarkdownEditor } from "components/Shared/MarkdownEditor/MarkdownEditor";

export const OrderInvoice = (props: OrderInvoiceProps): React.ReactElement => {
  const {
    orderDetail,
    paymentMethod,
    orderInvoiceForm,
    handleFormChange,
    handleFormItemChange,
    formIsValid,
    dialog,
    setDialog,
    sendInvoiceViaWhatsApp,
    loaders,
    handlePreview,
    baseUrl,
    sendInvoiceEmail,
    sendInvoiceViaEmail,
    exportInvoiceAsPDF,
    downloadInvoice,
    sendWhatsappMessage
  } = props;
  const appState = useAppState();
  const invoiceUrl = `${baseUrl}/${orderDetail?.id}`;

  return (
    <div className="fixed left-0 top-0 right-0 w-screen h-screen bg-soft-grey flex justify-center px-0 sm:px-12 xl:px-40 py-6 xl:pt-10 z-10 overflow-y-auto">
      <div className="w-full md:5/6 xl:w-2/3">
        <Link to="./../" className="font-bold text-sm	flex px-4 md:px-0 cursor-pointer">
          <span className="text-primary mr-1">
            <ArrowLeft />
          </span>
          Back to order
        </Link>
        <div className="bg-white rounded-lg mt-4">
          <div className="flex justify-between p-6 border-b-2 border-soft-grey">
            <Title className="md:text-3xl">Invoice</Title>
            <DropdownButton
              items={[
                {
                  text: "Send via Email",
                  onClick: sendInvoiceViaEmail
                },
                {
                  text: "Send via WhatsApp",
                  onClick: sendInvoiceViaWhatsApp
                },
                {
                  text: "Export as PDF",
                  onClick: exportInvoiceAsPDF
                },
                {
                  text: "Preview",
                  onClick: handlePreview
                }
              ]}>
              {(props) => (
                <Button size="md" disabled={!formIsValid} loading={loaders.generatingInvoice}>
                  Send invoice
                  <Caret {...props} />
                </Button>
              )}
            </DropdownButton>
          </div>
          <div className="p-6">
            <h3 className="text-xl font-bold">Bill to:</h3>
            <div className="flex flex-wrap mt-2">
              <div className="w-full md:w-1/2">
                <p>
                  <b>{orderDetail?.customer.name}</b>
                </p>
                <p>{orderDetail?.customer.email}</p>
              </div>
            </div>
          </div>
          <div className="justify-between hidden px-6 md:flex mt-6 py-3 bg-soft-grey border-b">
            <p className="text-grey-50 font-bold w-1/2">Items</p>
            <p className="text-grey-50 font-bold w-1/6 text-right">Quantity</p>
            <p className="text-grey-50 font-bold w-1/6 text-right">Unit price</p>
            <p className="text-grey-50 font-bold w-1/6 text-right">Amount</p>
          </div>
          {orderInvoiceForm.items.map((item, index) => (
            <div
              key={item.orderItemGroupId}
              className="flex items-center px-6 border-b-2 border-soft-grey flex-wrap">
              <div className="w-full md:w-1/2 py-3">
                <TextInput
                  name="name"
                  id="name"
                  value={item.name}
                  onChange={(e) => handleFormItemChange(e, index)}
                  label=""
                  inputSize="sm"
                  error={!isNotEmpty(item.name)}
                />
                <div className="overflow-hidden max-w-full no-scrollbar">
                  <InlineInput
                    name="description"
                    id={`description${index}`}
                    value={item.description}
                    onChange={(e) => handleFormItemChange(e, index)}
                    placeholder="Add description"
                    className=" font-normal text-sm py-0"
                    defaultSize={13}
                    padSize={0}
                    onFocus={(e) => {
                      if (!item.description) {
                        handleFormItemChange(
                          {
                            ...e,
                            target: {
                              ...e.target,
                              value: item.defaultDescription,
                              name: "description"
                            }
                          },
                          index
                        );
                      }
                    }}
                  />
                </div>
              </div>
              <div className="w-1/3 md:w-1/6 pb-3 md:pt-3 md:mb-6 md:text-right">
                <small className="md:hidden">
                  Quantity
                  <br />
                </small>
                {item.quantity}
              </div>
              <div className="w-1/3 md:w-1/6 pb-3 md:pt-3 md:mb-6 text-right">
                <small className="md:hidden">
                  Unit price
                  <br />
                </small>
                <Amount amount={item.unitPrice} />
              </div>
              <div className="w-1/3 md:w-1/6 pb-3 md:pt-3 md:mb-6 text-right">
                <small className="md:hidden">
                  Amount
                  <br />
                </small>
                <Amount amount={item.totalPrice} />
              </div>
            </div>
          ))}
          {(orderDetail?.discount || orderDetail?.tax) && (
            <div className="flex pt-8">
              <div className="w-2/3 md:w-5/6 text-right">Sub total:</div>
              <div className="w-1/3 md:w-1/6 text-right px-6">
                <Amount amount={orderDetail?.subTotal} />
              </div>
            </div>
          )}
          {orderDetail?.discount && (
            <div className="flex text-primary pt-2">
              <div className="w-2/3 md:w-5/6 text-right">Discount:</div>
              <div className="w-1/3 md:w-1/6 text-right px-6">
                (<Amount amount={orderDetail?.discount?.discountedAmount} />)
              </div>
            </div>
          )}
          {orderDetail?.tax && (
            <div className="flex pt-2 pb-4">
              <div className="w-2/3 md:w-5/6 text-right">Tax:</div>
              <div className="w-1/3 md:w-1/6 text-right px-6">
                (<Amount amount={orderDetail?.tax?.taxAmount} />)
              </div>
            </div>
          )}
          <div className="flex py-4 border-t-2 border-soft-grey">
            <div className="w-2/3 md:w-5/6 text-right">Total:</div>
            <div className="w-1/3 md:w-1/6 text-right px-6">
              <Amount amount={orderDetail?.amountTotal} />
            </div>
          </div>
          <div className="mt-6 p-6">
            <h3 className="text-xl font-bold">Payment</h3>
          </div>
          <div className="px-6 pb-4">
            <div className="w-60 space-y-2">
              <label htmlFor="dueDate">Payment due on:</label>
              <DateInput
                name="dueDate"
                id="dueDate"
                onChange={handleFormChange}
                value={orderInvoiceForm.dueDate}
                label=""
                data-testid="dueDateInput"
                inputSize="sm"
              />
            </div>
          </div>
          <div className="px-6 pb-4">
            <div className="w-60 space-y-2">
              <label htmlFor="businessPaymentMethodId">Payment method:</label>
            </div>
            {paymentMethod.businessPaymentMethods.map((method) => (
              <div className="mb-2" key={method.id}>
                <Radio
                  data-testid="businessPaymentMethodId"
                  onChange={handleFormChange}
                  label={
                    <span className="inline-flex gap-2">
                      <b>{method.name}</b>{" "}
                      {orderInvoiceForm.businessPaymentMethodId == method.id && (
                        <span
                          className="link text-sm"
                          onClick={() => {
                            paymentMethod.setBusinessPaymentMethod(method);
                            paymentMethod.setIsOpen(true);
                          }}>
                          <Edit />
                        </span>
                      )}
                    </span>
                  }
                  value={method.id}
                  id={method.id}
                  checked={orderInvoiceForm.businessPaymentMethodId == method.id}
                  name="businessPaymentMethodId"
                />

                {orderInvoiceForm.businessPaymentMethodId == method.id && (
                  <div className="ml-6 mt-1 mb-2 text-sm">
                    {method.paymentMethodName == BANK_DEPOSIT && (
                      <>
                        <div>Bank: {method.details.bank}</div>
                        <div>Account Number: {method.details.accountNumber}</div>
                        <div>Account Name: {method.details.accountName}</div>
                      </>
                    )}
                    {method.paymentMethodName == PAYMENT_LINK && (
                      <a
                        href={method.details.paymentLink}
                        rel="noreferrer"
                        className="link underline"
                        target="_blank">
                        {method.details.paymentLink}
                      </a>
                    )}
                  </div>
                )}
              </div>
            ))}
            {paymentMethod.businessPaymentMethods.length == 0 ? (
              <div className="text-sm">
                No payment methods,{" "}
                <span
                  className="link inline-flex text-sm mt-4"
                  onClick={() => {
                    paymentMethod.resetForm();
                    paymentMethod.setIsOpen(true);
                  }}>
                  Create
                </span>
              </div>
            ) : (
              <span
                className="link flex items-center text-sm mt-4"
                onClick={() => {
                  paymentMethod.resetForm();
                  paymentMethod.setIsOpen(true);
                }}>
                <Plus className="mr-2" width="24" height="24" />
                Add payment method
              </span>
            )}
            <PaymentMethodsDialog {...paymentMethod} />
          </div>
          <div className="px-6 mt-4 pb-4 space-y-2 border-b-2 border-soft-grey">
            <label htmlFor="note">Notes/Terms</label>
            <div className="border-soft-grey rounded-lg border-2 markdown">
              <MarkdownEditor
                markdown={orderInvoiceForm.note}
                onChange={(markdown: string) =>
                  handleFormChange({
                    target: { name: "note", value: markdown }
                  } as FormChangeEvent)
                }
                placeholder=""
              />
            </div>
          </div>
          <div className="p-6 pb-12 flex items-center">
            <Initials
              text={appState.business.get()?.name}
              imgUrl={appState.business.get()?.logoUrl}
            />
            <span className="flex flex-col items-start ml-3">
              <span className="font-bold" data-testid="businessName">
                {appState.business.get()?.name}
              </span>
            </span>
          </div>
        </div>
      </div>
      <Dialog
        isOpen={dialog == "whatsapp"}
        setIsOpen={(open: boolean): void => {
          setDialog(open ? "whatsapp" : "");
        }}
        title="Send invoice via WhatsApp"
        large
        titleClassName="bg-soft-grey border-b border-grey-30"
        contentClassName="p-0">
        <div className="flex flex-col md:flex-row">
          <div className="p-6 md:w-1/2">
            <TextInput
              name="whatsAppMessage"
              onChange={handleFormChange}
              value={orderInvoiceForm.whatsAppMessage}
              label="Message"
              data-testid="whatsAppMessage"
              multiLine
            />
            <div className="flex gap-4">
              <IconButton className="px-6" outlined onClick={() => setDialog("")}>
                Cancel
              </IconButton>
              <a onClick={sendWhatsappMessage}>
                <Button className="px-6" size="md">
                  Send
                </Button>
              </a>
            </div>
          </div>
          <div className="bg-soft-grey px-6 pb-6 md:w-1/2 flex justify-center md:rounded-br-lg">
            <div className="w-[311px] md:h-[400px] relative">
              <img src="/images/svg/whatsapp.svg" className="shadow-md rounded-b-xl" />
              <div className="absolute bg-[#DCF7C5] rounded-md px-4 py-6 right-4 w-2/3 text-xs md:text-sm shadow bottom-1/4 break-all">
                {orderInvoiceForm.whatsAppMessage}
                <br /> <br />
                View invoice: &nbsp;
                <a className="underline text-[#3497F9]">{invoiceUrl}</a>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialog == "email"}
        setIsOpen={(open: boolean): void => {
          setDialog(open ? "email" : "");
        }}
        title="Send invoice via Email"
        large
        titleClassName="bg-soft-grey border-b border-grey-30"
        contentClassName="p-0">
        <div className="flex flex-col md:flex-row">
          <div className="p-6 md:w-1/2">
            <TextInput
              name="email"
              onChange={handleFormChange}
              value={orderInvoiceForm.email}
              required
              label="To"
              data-testid="email"
            />
            <TextInput
              name="emailMessage"
              onChange={handleFormChange}
              value={orderInvoiceForm.emailMessage}
              label="Message (Optional)"
              data-testid="emailMessage"
              multiLine
              maxLength={160}
            />
            <div className="flex gap-4">
              <IconButton className="px-6" outlined onClick={() => setDialog("")}>
                Cancel
              </IconButton>
              <Button
                className="px-6"
                size="md"
                disabled={!isEmail(orderInvoiceForm.email)}
                loading={loaders.emailingInvoice}
                onClick={sendInvoiceEmail}>
                Send
              </Button>
            </div>
          </div>
          <div className="bg-soft-grey px-6 pb-6 md:w-1/2 flex flex-col justify-center items-center md:rounded-br-lg p-6">
            <Initials
              text={appState.business.get()?.name}
              imgUrl={appState.business.get()?.logoUrl}
            />
            <div className="mt-4 w-full p-6 font-normal bg-white border-t-[6px] border-primary text-center space-y-6">
              <p>{appState.business.get()?.name} has sent you an invoice of</p>
              <div>
                <h3 className="text-3xl md:text-4xl font-bold mb-1">
                  <Amount amount={orderDetail?.amountTotal} />
                </h3>
                <p>Due on {formatReadableDate(orderInvoiceForm.dueDate)}</p>
              </div>
              <a className="block" target="_blank" rel="noreferrer" href={invoiceUrl}>
                <Button className="px-6" size="md">
                  View invoice
                </Button>
              </a>
              <p>{orderInvoiceForm.emailMessage || "Custom message goes here (if any)"}</p>
            </div>
            <a target="_blank" rel="noreferrer" href="/" className="pt-4">
              <img src="/logo.svg" alt="Bloom Logo" className="w-20 sm:w-28" />
            </a>
          </div>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialog == "pdf"}
        setIsOpen={(open: boolean): void => {
          setDialog(open ? "pdf" : "");
        }}
        title="Export as PDF"
        large
        titleClassName="bg-soft-grey border-b border-grey-30"
        contentClassName="p-0">
        <div className="p-4 md:p-16 flex flex-col">
          <div className="text-center pb-6">
            <img src="/images/svg/pdf.svg" alt="" />
            <p className="font-semibold mt-4">Your invoice is ready to be downloaded as a PDF.</p>
          </div>
          <div className="flex gap-2 md:gap-4 justify-center">
            <IconButton className="px-6" outlined onClick={() => setDialog("")}>
              Cancel
            </IconButton>
            <Button
              className="px-6"
              size="md"
              loading={loaders.downloadingInvoice}
              onClick={downloadInvoice}>
              Download PDF
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
