import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { storage } from "config/firebase";
import { hasFeature } from "components/Layout/Dashboard/Dashboard.utils";
import api from "config/api";
import { useAppState } from "config/store";
import { ERROR_OCCURRED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { isNotEmpty } from "helpers/validate";
import useBusiness from "hooks/useBusiness";
import { useEffect, useState } from "react";
import { BusinessForm, FormChangeEvent, SettingsLoaders, UseSettingsType, Response } from "types";
import { AlertType, FeatureFlag, SegmentEvent } from "types/enum";
import { countriesList, CountryDescription } from "helpers/countries";
import { QueryClient } from "@tanstack/react-query";

const useSettings = (): UseSettingsType => {
  const { showAlert } = useAlert();
  const queryClient = new QueryClient();
  const { handleGetUserBusinesses, handleGetBusiness } = useBusiness();
  const [countries, setCountries] = useState<CountryDescription[]>([]);

  const appState = useAppState();
  const bussiness = appState.business.get();

  const storeEnabled = hasFeature(FeatureFlag.STORE, appState.business.get()?.features);
  const templatesEnabled = hasFeature(FeatureFlag.TEMPLATES, appState.business.get()?.features);

  const [businessForm, setBusinessForm] = useState<BusinessForm>({
    businessType: "",
    businessName: "",
    currency: "",
    logoUrl: "",
    phoneNumber: "",
    email: "",
    addressline1: "",
    city: "",
    state: "",
    country: ""
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [loaders, setLoaders] = useState<SettingsLoaders>({
    savingSettings: false,
    togglingFeature: false,
    loadingLogoUpload: false
  });
  const [error, setError] = useState("");

  const handleEditBusiness = async (requestData: {
    businessName?: string;
    phoneNumber?: string;
    logoUrl?: string;
    email?: string;
    address?: {
      addressline1: string;
      city?: string;
      state: string;
      country: string;
    };
  }): Promise<boolean> => {
    try {
      const json: Response<string> = await api.put("business", { json: requestData }).json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.BUSINESS_MODIFIED, {
          name: businessForm.businessName
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const updateBusinessLogo = async (args: { logoUrl: string }): Promise<boolean> => {
    try {
      const json: Response<string> = await api
        .put("business", {
          json: {
            logoUrl: args.logoUrl
          }
        })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.BUSINESS_MODIFIED, {
          name: businessForm.businessName
        });
        queryClient.invalidateQueries({ queryKey: ["onboarding-actions"] });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleToggleFeature = async (feature: FeatureFlag): Promise<void> => {
    try {
      setLoaders((prev) => ({ ...prev, togglingFeature: true }));

      const json: Response<string> = await api.put(`features/toggle/${feature}`).json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.FEATURE_TOGGLED, {
          feature
        });
        await handleGetBusiness(appState.activeBusinessId.get() || "");
      }
      setLoaders((prev) => ({ ...prev, togglingFeature: false }));
      return;
    } catch {
      return;
    }
  };

  const handleImageUpload = async (image: File): Promise<void> => {
    setLoaders((prev) => ({ ...prev, loadingLogoUpload: true }));

    const storageRef = ref(storage, `business/${bussiness?.id}/logo/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // You can add progress tracking here if needed
      },
      (error) => {
        console.error("Image upload failed:", error);
        showAlert(AlertType.DANGER, "Image upload failed");
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setBusinessForm((prev) => ({
            ...prev,
            logoUrl: downloadURL
          }));
          await updateBusinessLogo({ logoUrl: downloadURL });
          showAlert(AlertType.SUCCESS, "Image uploaded successfully");
          queryClient.invalidateQueries({ queryKey: ["onboarding-actions"] });
        } catch (error) {
          console.error("Failed to get download URL:", error);
          showAlert(AlertType.DANGER, "Failed to get download URL");
        }
        setLoaders((prev) => ({ ...prev, loadingLogoUpload: false }));
      }
    );
  };

  const handleLogeDelete = async () => {
    try {
      setLoaders((prev) => ({ ...prev, loadingLogoUpload: true }));
      setBusinessForm((prev) => ({
        ...prev,
        logoUrl: ""
      }));
      setLoaders((prev) => ({ ...prev, loadingLogoUpload: false }));
    } catch {
      return false;
    }
  };

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setBusinessForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const fetchCountries = async (): Promise<void> => {
    setCountries(countriesList);
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setError("");

    setLoaders((prev) => ({ ...prev, savingSettings: true }));
    const result = await handleEditBusiness({
      businessName: businessForm.businessName
    });

    setLoaders((prev) => ({ ...prev, savingSettings: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
      await Promise.all([
        handleGetBusiness(appState.activeBusinessId.get() || ""),
        handleGetUserBusinesses()
      ]);
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  const handleContactFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setError("");

    setLoaders((prev) => ({ ...prev, savingSettings: true }));
    const result = await handleEditBusiness({
      phoneNumber: businessForm.phoneNumber,
      email: businessForm.email,
      address: {
        addressline1: businessForm.addressline1,
        city: businessForm.city,
        state: businessForm.state,
        country: businessForm.country
      }
    });

    setLoaders((prev) => ({ ...prev, savingSettings: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
      await Promise.all([
        handleGetBusiness(appState.activeBusinessId.get() || ""),
        handleGetUserBusinesses()
      ]);
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  // UseEffects
  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    setFormIsValid(
      isNotEmpty(businessForm.businessName) && businessForm.businessName != bussiness?.name
    );
  }, [businessForm, bussiness?.id]);

  useEffect(() => {
    console.log("bussiness is changing ", bussiness);
    if (bussiness) {
      setBusinessForm({
        businessType: bussiness.businessType,
        businessName: bussiness.name,
        currency: bussiness.currency,
        logoUrl: bussiness.logoUrl,
        phoneNumber: bussiness.phoneNumber,
        email: bussiness.email,
        addressline1: bussiness.address.addressline1,
        city: bussiness.address.city,
        state: bussiness.address.state,
        country: bussiness.address.country
      });
    }
  }, [bussiness?.id]);

  return {
    storeEnabled,
    businessForm,
    loaders,
    error,
    handleFormChange,
    handleImageUpload,
    handleLogeDelete,
    formIsValid,
    countries,
    handleFormSubmit,
    handleToggleFeature,
    handleContactFormSubmit,
    templatesEnabled
  };
};

export default useSettings;
