import IconButton from "components/Shared/IconButton";
import { Upload } from "icons/Upload";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { SettingsProps } from "types";

const fileTypes = ["jpg", "png", "gif", "jpeg", "svg"];

export const LogoUpload = ({
  handleImageUpload,
  handleLogeDelete,
  businessForm,
  loaders
}: SettingsProps) => {
  return (
    <div className="">
      {!businessForm.logoUrl ? (
        <FileUploader handleChange={handleImageUpload} name="file" types={fileTypes}>
          <div className="flex flex-col justify-center items-center p-6 py-10 border-1 border border-dark-grey border-dashed rounded-lg">
            <Upload className="mb-2" />
            <p className="mt-2 mb-2">Select your logo or drag and drop here</p>
            <p className="text-grey-50">Maximum 5MB in size.</p>
            <p className="text-grey-50">JPG, PNG, or GIF formats.</p>
            <p className="text-grey-50 mb-3">Recommended size: 300 x 300 pixels.</p>
            <IconButton outlined loading={loaders.loadingLogoUpload}>
              Select Image
            </IconButton>
          </div>
        </FileUploader>
      ) : (
        <div>
          <div className="flex flex-col md:flex-row items-center justify-between mt-4">
            <div className="flex items-center">
              <img
                src={businessForm.logoUrl}
                alt="Business logo"
                className="w-48 h-48 rounded-lg"
              />
            </div>
          </div>
          <button
            onClick={() => handleLogeDelete()}
            type="button"
            className="mt-2 link cursor-pointer inline-flex">
            <b>Change logo</b>
          </button>
        </div>
      )}
    </div>
  );
};
