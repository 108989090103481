import { useAppState } from "config/store";
import { useComponentVisible } from "hooks/shared/useComponentVisible/useComponentVisible";
import CheckCircle from "icons/CheckCircle";
import Plus from "icons/Plus";
import Power from "icons/Power";
import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import "./ProfileDropdown.css";
import Initials from "components/Shared/Initials/Initials";
import Caret from "icons/Caret";

const ProfileDropdown = (): React.ReactElement => {
  const appState = useAppState();
  const { ref, isComponentVisible } = useComponentVisible<HTMLSpanElement>(false);
  const businesses = appState.member.get()?.businesses || [];
  const activeBusinessId = appState.activeBusinessId.get();

  return (
    <span
      id="dropdownDefaultButton"
      data-dropdown-toggle="dropdown"
      data-dropdown-placement="top"
      className="bg-white font-medium rounded-lg text-sm pl-4 items-center justify-between text-dark-grey relative">
      <div
        className={cx(
          "bg-dark-grey text-white rounded-lg absolute left-0 right-0 bottom-full py-2 hidden",
          isComponentVisible && "!block"
        )}>
        <div className="overflow-auto max-h-60 custom-scroll">
          {businesses.map((business) => (
            <span
              className="flex px-4 py-2 space-x-3 items-center cursor-pointer"
              key={business.id}
              onClick={(): void => appState.activeBusinessId.set(business.id)}>
              <Initials text={business.name} imgUrl={business.logoUrl} />
              <span className="font-bold text-white" data-testid="businessName">
                {business.name}
              </span>
              <span className="flex-1"></span>
              {business.id == activeBusinessId && <CheckCircle />}
            </span>
          ))}
        </div>
        <Link to="/onboarding/business" className="flex px-4 py-2 space-x-5 items-center">
          <Plus width="24" height="24" /> <span>Add business</span>
        </Link>
        <Link
          to="/signin"
          className="flex px-4 py-2 space-x-5 items-center border-t border-t-grey-50">
          <Power /> <span>Sign out</span>
        </Link>
      </div>
      <span className="flex py-3 cursor-pointer" ref={ref}>
        <Initials text={appState.business.get()?.name} imgUrl={appState.business.get()?.logoUrl} />
        <span className="flex flex-col items-start ml-3">
          <span className="text-grey-50 text-xs" data-testid="ownerName">
            {appState.business.get()?.user.name}
          </span>
          <span className="font-bold" data-testid="businessName">
            {appState.business.get()?.name}
          </span>
        </span>
        <span className="flex-1"></span>
        <Caret open={isComponentVisible} className="mr-4 my-3 flex-1" />
      </span>
    </span>
  );
};

export default ProfileDropdown;
