import { SettingsHeaderTab } from "components/Settings/Settings";
import ConfirmationDialog from "components/Shared/ConfirmationDialog";
import Disclosure from "components/Shared/Disclosure";
import Dropdown from "components/Shared/Dropdown";
import IconButton from "components/Shared/IconButton";
import Table from "components/Shared/Table";
import Delete from "icons/Delete";
import Edit from "icons/Edit";
import Kebeb from "icons/Kebeb";
import Plus from "icons/Plus";
import React from "react";
import { Link } from "react-router-dom";
import { MembersProps } from "types";

const Members = (props: MembersProps): React.ReactElement => {
  const {
    users,
    deleteConfirmationMessage,
    handleDeleteConfirmation,
    handleDeleteCancellation,
    handleConfirmDeleteInvite,
    handleConfirmDeleteMember
  } = props;
  return (
    <div className="px-4 sm:px-0 pb-8">
      <div className="space-y-4">
        <SettingsHeaderTab selectedIndex={1} />
        <Disclosure data-testid="pendingInvites" className="!bg-white">
          <p className="font-bold">Pending Invites ({users?.pendingInvites.length || 0})</p>
          <div className="pt-2 text-sm">
            {users.pendingInvites.length == 0 && <p className="">No pending invites sent</p>}
            {users.pendingInvites.length > 0 && (
              <div className="flex py-2 flex-wrap">
                <span className="font-bold text-grey-50 w-4/5">Email</span>
                <span className="font-bold text-grey-50 w-1/5 md:w-1/5"></span>
                <span className="w-1/5"></span>
              </div>
            )}
            {users.pendingInvites.map((invite) => (
              <div key={invite.id}>
                <span className="flex py-0 md:py-2 flex-wrap">
                  <span
                    className="text-sm md:text-base font-bold w-4/5 break-words"
                    data-testid="inviteEmail">
                    {invite.email}
                  </span>
                  <span className="font-bold w-1/5 text-right" data-testid="">
                    <button
                      className="text-red-500 mobile-link"
                      onClick={(): void => handleConfirmDeleteInvite(invite)}>
                      <Delete className="mobile-icon" />
                      <span className="hidden md:inline-flex">Delete</span>
                    </button>
                  </span>
                </span>
              </div>
            ))}
          </div>
        </Disclosure>

        <div className="bg-white sm:rounded-md p-4 lg:p-6 mt-2 md:mt-7">
          <div className="flex flex-col md:flex-row justify-between">
            <p className="font-bold mb-6 md:mb-0">Members</p>
            <Link to="/settings/members/create" data-testid="createMember" className="mb-2 sm:mb-0">
              <IconButton icon={<Plus />}>Add new member</IconButton>
            </Link>
          </div>
          <div className="hidden sm:block">
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.HeadData>Name</Table.HeadData>
                  <Table.HeadData>Email address</Table.HeadData>
                  <Table.HeadData />
                </Table.Row>
              </Table.Head>
              <Table.Body className="text-dark-grey" data-testid="storeItems">
                {users.members.map(
                  (member): React.ReactElement => (
                    <Table.Row key={member.userId} className="rounded-lg" data-testid="inviteItem">
                      <Table.Data data-testid="memberName">
                        <b>{member.name}</b>
                      </Table.Data>
                      <Table.Data data-testid="memberEmail">
                        <b>{member.email}</b>
                      </Table.Data>
                      <Table.Data className="text-dark-grey">
                        {!member.isBusinessOwner && (
                          <Dropdown
                            menu={[
                              {
                                text: "Edit",
                                icon: <Edit />,
                                link: `/settings/members/${member.userId}/edit`
                              },
                              {
                                text: "Delete",
                                icon: <Delete />,
                                onClick: () => handleConfirmDeleteMember(member)
                              }
                            ]}>
                            <Kebeb />
                          </Dropdown>
                        )}
                      </Table.Data>
                    </Table.Row>
                  )
                )}
              </Table.Body>
            </Table>
          </div>
          <div className="block sm:hidden mt-4">
            <div className="flex">
              <span className="font-bold text-grey-50 w-4/5 px-4">Name</span>
              <span className="w-1/5"></span>
            </div>
            {users.members.map(
              (member): React.ReactElement => (
                <Disclosure
                  key={member.userId}
                  data-testid="memberMobile"
                  expanded
                  closable={false}>
                  <div className="flex justify-between">
                    <Link to={`/settings/members/${member.userId}/edit`} className="flex-grow">
                      <span className="font-bold text-lg" data-testid="memberName">
                        <b>{member.name}</b>
                      </span>
                      <p className="mt-2 w-full mb-1" data-testid="memberEmail">
                        <span>{member.email}</span>
                      </p>
                    </Link>
                    <Dropdown
                      menu={[
                        {
                          text: "Edit",
                          icon: <Edit />,
                          link: `/settings/members/${member.userId}/edit`
                        },
                        {
                          text: "Delete",
                          icon: <Delete />,
                          onClick: () => handleConfirmDeleteMember(member)
                        }
                      ]}>
                      <Kebeb />
                    </Dropdown>
                  </div>
                </Disclosure>
              )
            )}
          </div>
          {users.members.length == 0 && (
            <div data-testid="noMembers" className="p-4 md:px-6">
              No members created
            </div>
          )}
        </div>
      </div>
      <ConfirmationDialog
        message={deleteConfirmationMessage}
        handleContinue={handleDeleteConfirmation}
        handleCancel={handleDeleteCancellation}
      />
    </div>
  );
};

export default Members;
