import React, { useState } from "react";
import { CreateEditExpenseProps } from "types";
import { Link } from "react-router-dom";
import ArrowLeft from "icons/ArrowLeft";
import Error from "components/Shared/Error";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import Select from "components/Shared/Select";
import Toggle from "components/Shared/Toggle";
import Title from "components/Shared/Title";
import SubTitle from "components/Shared/SubTitle";
import { ExpenseCategory, Period, periodToLabel } from "types/enum";
import MonthInput from "components/Shared/MonthInput";
import Stepper from "components/Shared/Stepper";
import Form from "components/Shared/Form";
import AllocateActivities from "components/AllocateActivities";
import Amount from "components/Shared/Amount";

const CreateEditExpense = (props: CreateEditExpenseProps): React.ReactElement => {
  const {
    expenseForm,
    handleFormChange,
    handleFormSubmit,
    formIsValid,
    error,
    handleFormToggleChange,
    expenseTypes,
    loaders,
    expenseId,
    expense
  } = props;
  const isCreatingNewExpense = !expenseId;
  const [activeStep, setActiveStep] = useState("Expense details");
  return (
    <div className="bg-white rounded-lg py-form mobile-full-screen">
      <div className="form-xy">
        <Link to="/expenses" className="font-bold text-sm	inline-flex" data-testid="backLink">
          <span className="text-primary mr-1">
            <ArrowLeft />
          </span>
          Back to expenses
        </Link>
      </div>
      <Title className="text-center mt-6 md:mt-0">
        {isCreatingNewExpense ? "Add new expense" : `Edit ${expense?.name}`}
      </Title>
      {activeStep == "Expense details" && (
        <SubTitle>Save expenses to monitor your cash flow and track your expenses.</SubTitle>
      )}
      {activeStep == "Allocate activities" && (
        <SubTitle>
          Drag the radar to allocate a percentage of each activity to your {expenseForm.name} &nbsp;
          expense.
        </SubTitle>
      )}
      <div className="my-8">
        <Stepper
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          steps={["Expense details", "Allocate activities"]}
        />
      </div>
      <form
        onSubmit={handleFormSubmit}
        // className="mt-10 md:mx-12 xl:mx-40"
        data-testid="expenseForm">
        <div className="px-4 md:px-10">
          <Error error={error} />
        </div>
        {activeStep == "Expense details" && (
          <>
            <div className="form-center mt-4">
              <div className="">
                <TextInput
                  type="text"
                  name="name"
                  onChange={handleFormChange}
                  value={expenseForm.name}
                  required
                  label="Name"
                  data-testid="nameInput"
                />
              </div>
              {isCreatingNewExpense && (
                <>
                  <div>
                    <Select
                      id="expenseCategory"
                      name="expenseCategory"
                      value={expenseForm.expenseCategory}
                      required
                      onChange={handleFormChange}
                      data-testid="expenseCategory"
                      label="Category">
                      <option value="" disabled>
                        Select a category
                      </option>
                      {expenseTypes.map((expenseType) => (
                        <option key={expenseType.name}>{expenseType.name}</option>
                      ))}
                    </Select>
                  </div>
                  {expenseForm.expenseCategory === ExpenseCategory.OTHER && (
                    <div>
                      <TextInput
                        type="text"
                        name="otherExpenseCategory"
                        id="otherExpenseCategory"
                        onChange={handleFormChange}
                        data-testid="otherExpenseCategory"
                        value={expenseForm.otherExpenseCategory || ""}
                        required={true}
                        label="Other expense category"
                      />
                    </div>
                  )}
                </>
              )}
              <div>
                <TextInput
                  type="number"
                  name="amount"
                  id="amount"
                  onChange={handleFormChange}
                  value={expenseForm.amount}
                  required={true}
                  label="Amount"
                  data-testid="amountInput"
                />
                <small className="pl-4 -mt-4 block pb-2">
                  <Amount amount={+expenseForm.amount} />
                </small>
              </div>
              {/* {isCreatingNewExpense && ( */}
              <div>
                <Select
                  id="period"
                  name="period"
                  value={expenseForm.period}
                  required
                  onChange={handleFormChange}
                  label="Period"
                  data-testid="period">
                  <option value="" disabled>
                    Select a period
                  </option>
                  <option value={Period.MONTH}>{periodToLabel(Period.MONTH)}</option>
                  <option value={Period.THREE_MONTHS}>{periodToLabel(Period.THREE_MONTHS)}</option>
                  <option value={Period.SIX_MONTHS}>{periodToLabel(Period.SIX_MONTHS)}</option>
                  <option value={Period.NINE_MONTHS}>{periodToLabel(Period.NINE_MONTHS)}</option>
                  <option value={Period.YEAR}>{periodToLabel(Period.YEAR)}</option>
                  <option value={Period.TWO_YEARS}>{periodToLabel(Period.TWO_YEARS)}</option>
                  <option value={Period.THREE_YEARS}>{periodToLabel(Period.THREE_YEARS)}</option>
                  <option value={Period.FOUR_YEARS}>{periodToLabel(Period.FOUR_YEARS)}</option>
                  <option value={Period.CUSTOM}>{periodToLabel(Period.CUSTOM)}</option>
                </Select>
              </div>
              {/* )} */}
              <div className="flex space-x-2">
                <div className="flex-1">
                  <MonthInput
                    name="startDate"
                    id="startDate"
                    data-testid="startDate"
                    onChange={handleFormChange}
                    value={expenseForm.startDate}
                    required={true}
                    label="Start Month:"
                  />
                </div>
                {expenseForm.period === Period.CUSTOM && (
                  <div className="w-1/2">
                    <MonthInput
                      name="endDate"
                      id="endDate"
                      data-testid="endDate"
                      onChange={handleFormChange}
                      value={expenseForm.endDate}
                      required={true}
                      min={expenseForm.startDate}
                      monthFormat="end"
                      label="End Month:"
                    />
                  </div>
                )}
              </div>
              <div>
                <Toggle
                  checked={expenseForm.isRecurring}
                  onChange={() => handleFormToggleChange({ isRecurring: !expenseForm.isRecurring })}
                  id="isRecurring"
                  name="isRecurring"
                  data-testid="isRecurring"
                  label="Is Recurring Payment"
                />
              </div>
            </div>
            <Form.Footer>
              <Button
                type="button"
                disabled={!formIsValid}
                onClick={(): void => setActiveStep("Allocate activities")}
                className="w-full md:w-60"
                data-testid="continue">
                Continue
              </Button>
            </Form.Footer>
          </>
        )}
        {activeStep == "Allocate activities" && (
          <>
            <div className="form-center">
              <AllocateActivities {...props} />
            </div>
            <Form.Footer spaceBetween>
              <Button
                type="button"
                onClick={(): void => setActiveStep("Expense details")}
                className="w-auto min-w-32 md:w-60 !bg-black"
                gradient={false}
                data-testid="back">
                Go back
              </Button>
              <Button
                type="submit"
                disabled={!formIsValid}
                loading={loaders.savingExpense}
                data-testid="submitButton"
                className="w-auto min-w-32 md:w-60">
                Save
              </Button>
            </Form.Footer>
          </>
        )}
      </form>
    </div>
  );
};

export default CreateEditExpense;
