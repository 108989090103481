import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";
import LoadingComponent from "components/Shared/Loading";
import Title from "components/Shared/Title";
import { ProductOfferings } from "components/Shopfront/ProductOfferings/ProductOfferings";
import { useProductOfferings } from "hooks/useProductOfferings/useProductOfferings";
import React from "react";

export const OfferingsPage = (): React.ReactElement => {
  const { loaders, productOfferings } = useProductOfferings();

  return (
    <LoadingComponent loading={loaders.fetchingOfferings}>
      <div className="px-4 md:px-0">
        <div className="flex flex-col sm:flex-row justify-between sm:items-center space-y-4 sm:space-y-0 mb-6">
          <Title className="py-2" data-testid="title">
            Offerings
            <InfoTooltip>
              Package your products for customers as offerings then group offerings together to form
              collections.
            </InfoTooltip>
          </Title>
        </div>
        <ProductOfferings productOfferings={productOfferings} />
      </div>
    </LoadingComponent>
  );
};
