import { AutosaveButton } from "components/Shared/AutosaveButton/AutosaveButton";
import Error from "components/Shared/Error";
import React from "react";
import { ProductComponentProps } from "types";
import { AttachComponents } from "./AttachComponents";
import { VariantComponents } from "./VariantComponents";

const Components = (props: ProductComponentProps): React.ReactElement => {
  const {
    storeItems,
    handleAttachComponent,
    handleDetachComponent,
    components,
    formulas,
    handleVariantComponentsChange,
    measurementUnits,
    loaders,
    handleFormSubmit,
    error,
    hasUnsavedChanges,
    variantsHaveChanges,
    productName,
    setCreateIsOpen
  } = props;

  return (
    <div>
      <form
        className="pb-40"
        onSubmit={handleFormSubmit}
        data-testid="componentForm"
        autoComplete="off">
        <div className="bg-white md:rounded-lg px-4 md:px-6 py-4 mt-4 md:h-auto">
          <Error error={error} />
          <p className="text-grey-50 px-4 mb-4 md:px-0 mt-2 sm:mt-0" data-testid="componentDesc">
            Add store items and their usage measurement for these product variants.
          </p>
          <AttachComponents
            handleAttachComponent={handleAttachComponent}
            handleDetachComponent={handleDetachComponent}
            storeItems={storeItems}
            components={components}
            name={productName}
            setCreateIsOpen={setCreateIsOpen}
          />
          <VariantComponents
            variants={formulas.map((formula) => ({
              ...formula,
              variantMeasurements: formula.formulaParts
            }))}
            variantsHaveChanges={variantsHaveChanges}
            measurementUnits={measurementUnits}
            handleVariantComponentsChange={handleVariantComponentsChange}
            storeItems={storeItems}
            components={components}
          />
        </div>
        <div className="fixed left-0 right-0 bottom-0 bg-white sm:px-12 xl:px-40 py-6 text-center md:text-right z-20">
          <AutosaveButton
            disabled={!hasUnsavedChanges}
            hasChanges={hasUnsavedChanges}
            type="submit"
            loading={loaders.savingComponents}
            data-testid="submitButton"
            className="w-60"
          />
        </div>
      </form>
    </div>
  );
};

export default Components;
