import { useQuery } from "@tanstack/react-query";
import api from "config/api";
import { useAppState } from "config/store";
import { FETCH_FAILED, ONBOARDING_ERROR, ONBOARDING_MEMBER_ERROR } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { CurrencyDescription, currrencyDescriptions } from "helpers/currencies";
import { isNotEmpty } from "helpers/validate";
import useBusiness from "hooks/useBusiness";
import useInvite from "hooks/useInvite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BusinessType,
  FormChangeEvent,
  OnboardingForm,
  OnboardingLoaders,
  UseOnboardingType,
  Response,
  OnboardingActionsResponse,
  OnboardingAction
} from "types";
import { AlertType, SegmentEvent } from "types/enum";

const useOnboarding = (): UseOnboardingType => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { handleGetUserBusinesses, handleCreateBusiness, handleGetBusinessTypes } = useBusiness();
  const { handleAcceptInvite } = useInvite();
  const appState = useAppState();
  const user = appState.user.get();

  // UseStates
  const [onboardingForm, setOnboardingForm] = useState<OnboardingForm>({
    firstName: "",
    lastName: "",
    businessName: "",
    businessType: "",
    businessTypeId: "",
    currency: "",
    currencyCode: "",
    employees: "",
    howDidYouHear: ""
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [memberFormIsValid, setMemberFormIsValid] = useState(false);
  const [businessFormIsValid, setBusinessFormIsValid] = useState(false);
  const [loaders, setLoaders] = useState<OnboardingLoaders>({
    registeringBusiness: false,
    registeringUser: false
  });
  const [error, setError] = useState("");
  const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([]);
  const [currencies, setCurrencies] = useState<CurrencyDescription[]>([]);

  // Handlers
  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setOnboardingForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setLoaders((prev) => ({ ...prev, registeringBusiness: true }));
    const result = await handleCreateBusiness({
      ownerName: `${onboardingForm.firstName} ${onboardingForm.lastName}`,
      businessName: onboardingForm.businessName,
      businessType: onboardingForm.businessTypeId,
      currency: onboardingForm.currencyCode
    });
    setLoaders((prev) => ({ ...prev, registeringBusiness: false }));
    if (result) {
      track(SegmentEvent.BUSINESS_REGISTERED, {
        businessType: onboardingForm.businessType,
        businessId: result,
        plan: "Free Trial",
        employees: onboardingForm.employees,
        howDidYouHear: onboardingForm.howDidYouHear
      });
      showAlert(AlertType.SUCCESS);
      navigate("/post-auth");
    } else {
      setError(ONBOARDING_ERROR);
    }
  };

  const handleMemberFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setLoaders((prev) => ({ ...prev, registeringUser: true }));
    const json: Response<string> = await api
      .post("user", {
        json: {
          fullName: `${onboardingForm.firstName} ${onboardingForm.lastName}`,
          userId: user?.uid,
          email: user?.email
        }
      })
      .json();
    const result = json.code === 201;
    setLoaders((prev) => ({ ...prev, registeringUser: false }));
    if (result) {
      track(SegmentEvent.MEMBER_REGISTERED, {
        email: user?.email
      });
      await handleAcceptInvite();
      showAlert(AlertType.SUCCESS);
      navigate("/post-auth");
    } else {
      setError(ONBOARDING_MEMBER_ERROR);
    }
  };

  const handleBusinessFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setLoaders((prev) => ({ ...prev, registeringBusiness: true }));

    try {
      const json: Response<string> = await api
        .post("register/business", {
          json: {
            businessName: onboardingForm.businessName,
            businessType: onboardingForm.businessTypeId,
            currency: onboardingForm.currencyCode
          }
        })
        .json();
      const result = json.code === 201;
      setLoaders((prev) => ({ ...prev, registeringBusiness: false }));
      if (result) {
        track(SegmentEvent.BUSINESS_ADDED, {
          businessName: onboardingForm.businessName
        });
        showAlert(AlertType.SUCCESS);
        await handleGetUserBusinesses();
        appState.activeBusinessId.set(json.data);
        navigate("/overview");
      } else {
        setError(ONBOARDING_MEMBER_ERROR);
      }
    } catch {
      setLoaders((prev) => ({ ...prev, registeringBusiness: false }));
      setError(ONBOARDING_MEMBER_ERROR);
    }
  };

  const handleGoBack = (): void => {
    navigate(-1);
  };

  const fetchBusinessTypes = async (): Promise<void> => {
    setBusinessTypes(await handleGetBusinessTypes());
  };

  const fetchCurrencies = async (): Promise<void> => {
    setCurrencies(currrencyDescriptions);
  };

  const onboardingActionsQuery = useQuery({
    queryFn: async (): Promise<OnboardingAction[]> => {
      try {
        const json: Response<OnboardingActionsResponse> = await api
          .get("onboarding-actions")
          .json();
        if (json.code === 200) {
          return json.data.onboardingActions;
        }
      } catch (err) {
        showAlert(AlertType.DANGER, FETCH_FAILED);
        console.error(err);
      }
      return [];
    },
    queryKey: ["onboarding-actions"]
  });

  const getOnboardingAction = (actionId: string): boolean => {
    return !!onboardingActionsQuery.data?.find((action) => action.id == actionId)?.completed;
  };

  // UseEffects
  useEffect(() => {
    setFormIsValid(
      isNotEmpty(onboardingForm.firstName) &&
        isNotEmpty(onboardingForm.lastName) &&
        isNotEmpty(onboardingForm.businessName) &&
        isNotEmpty(onboardingForm.businessType) &&
        isNotEmpty(onboardingForm.howDidYouHear) &&
        isNotEmpty(onboardingForm.employees)
    );
    setMemberFormIsValid(
      isNotEmpty(onboardingForm.firstName) && isNotEmpty(onboardingForm.lastName)
    );
    setBusinessFormIsValid(
      isNotEmpty(onboardingForm.businessName) &&
        isNotEmpty(onboardingForm.businessType) &&
        isNotEmpty(onboardingForm.currencyCode)
    );
  }, [onboardingForm]);

  useEffect(() => {
    fetchBusinessTypes();
    fetchCurrencies();
  }, []);

  return {
    formIsValid,
    loaders,
    handleFormChange,
    handleFormSubmit,
    onboardingForm,
    error,
    businessTypes,
    currencies,
    handleMemberFormSubmit,
    email: user?.email as string,
    memberFormIsValid,
    businessFormIsValid,
    handleBusinessFormSubmit,
    handleGoBack,
    onboardingActions: onboardingActionsQuery?.data || [],
    getOnboardingAction,
    onboardingActionsIsFetching: onboardingActionsQuery.isFetching
  };
};

export default useOnboarding;
